'use client'

import { Global } from '@emotion/react'
import {
  toast,
  ToastContainer,
  type ToastContainerProps,
  type ToastContent,
  type ToastOptions
} from 'react-toastify'

export const NotifyContainer = (props: ToastContainerProps) => {
  return (
    <>
      <Global
        styles={{
          ':root': {
            '--toastify-font-family': 'var(--chakra-fonts-body)'
          }
        }}
      />

      <ToastContainer {...props} />
    </>
  )
}

const defaultOptions: Partial<ToastOptions> = {
  position: 'top-left',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true
} as const

export const notify = (content: ToastContent, toastOptions?: ToastOptions) => {
  return toast(content, {
    ...defaultOptions,
    ...toastOptions
  })
}
