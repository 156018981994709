import {
  Alert,
  AlertDescription,
  AlertIcon,
  type AlertProps,
  AlertTitle,
  Box,
  Button,
  HStack
} from '@chakra-ui/react'
import { useState } from 'react'
import { useNotificationsContext } from './context'

export const NotificationsBanner = (props: AlertProps) => {
  const { permission, requestToken } = useNotificationsContext()
  const isDefaultPermission = permission === 'default'
  const [isLoading, setIsLoading] = useState(false)

  const requestPermission = () => {
    setIsLoading(true)
    requestToken().finally(() => {
      setIsLoading(false)
    })
  }

  if (permission === 'granted') {
    return null
  }

  return (
    <Alert status={isDefaultPermission ? 'info' : 'warning'} {...props}>
      <AlertIcon />

      <HStack alignItems="center" justifyContent="space-between" w="100%">
        <Box mr="3">
          <AlertTitle>
            {isDefaultPermission ? 'Stay updated.' : 'Notifications are off.'}
          </AlertTitle>

          <AlertDescription>
            {isDefaultPermission
              ? 'Turn on the notifications for receiving them in real time.'
              : 'Turn on the notifications manually in browser’s settings and reload the page.'}
          </AlertDescription>
        </Box>

        {isDefaultPermission && (
          <Button size="sm" onClick={requestPermission} isLoading={isLoading} borderRadius="lg">
            Allow
          </Button>
        )}
      </HStack>
    </Alert>
  )
}
