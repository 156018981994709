import { AihubOffersProvider } from '../aihubOffersContext'
import { type AihubOffersContextState } from '../types'
import { useAiOffers } from '../useAiOffers'
import { AiHubOffersList } from './AihubOffersList'

interface AihubOffersProps {
  paths: AihubOffersContextState['paths']
  handleAddToCart: AihubOffersContextState['handleAddToCart']
  cartState: AihubOffersContextState['cartState']
}

export const AihubOffers = ({ paths, handleAddToCart, cartState }: AihubOffersProps) => {
  const { hideAiOffer } = useAiOffers()

  return (
    <AihubOffersProvider value={{ paths, handleAddToCart, cartState, hideAiOffer }}>
      <AiHubOffersList />
    </AihubOffersProvider>
  )
}
