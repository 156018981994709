import { useContext } from 'react'
import { GTMContext } from '../providers/gtm-provider'

export function useGTMContext() {
  const context = useContext(GTMContext)
  if (!context) {
    throw new Error('useGTMContext must be used within a GTMProvider')
  }
  return context
}
