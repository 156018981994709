import { type ChangeEvent, forwardRef, type KeyboardEvent } from 'react'
import { Box, IconButton, Input } from '@chakra-ui/react'
import { VscSend } from 'react-icons/vsc'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { overrideText } from 'ui/lib/overrides'
import { BG_GRADIENT } from './constants'
import { type AssistantTextOverrides } from './types'

interface ChatTextInputProps {
  onUpdate: (text: string) => void
  onSend: (text: string) => Promise<void>
  value: string
  isLoading: boolean
  isInit: boolean
}

const ChatTextInput = forwardRef<HTMLInputElement, ChatTextInputProps>((props, ref) => {
  const overrides = useOverridesContext<keyof AssistantTextOverrides>()
  const { onUpdate, onSend, value, isLoading, isInit } = props

  return (
    <Box w="full">
      <Box position="relative" w="full">
        <Box position="relative" borderRadius="full" bg={BG_GRADIENT} p="2px">
          <Input
            className="ai-widget__chat-input"
            bg="background.surface"
            borderRadius="full"
            py={5}
            shadow="md"
            errorBorderColor="status.error"
            name="chat-input"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              onUpdate(e.target.value)
            }}
            onKeyDown={(event: KeyboardEvent) => {
              if (event.key === 'Enter') {
                void onSend(value)
              }
            }}
            placeholder={overrideText('Svar', overrides?.assistantReply)}
            pr="40px"
            ref={ref}
            value={value}
            border="none"
            _focus={{ border: 'none' }}
            sx={{
              position: 'relative',
              bg: 'background.default',
              zIndex: 1
            }}
          />
        </Box>

        <IconButton
          aria-label="Send message"
          borderRadius="full"
          icon={<VscSend />}
          isDisabled={!value?.length || isLoading || !isInit}
          onClick={() => void onSend(value)}
          position="absolute"
          right={2}
          size="xs"
          top="50%"
          transform="translateY(-50%)"
          zIndex={2}
        />
      </Box>
    </Box>
  )
})

ChatTextInput.displayName = 'ChatTextInput'

export default ChatTextInput
