import { Box, type StackProps, VStack } from '@chakra-ui/react'
import { BG_GRADIENT } from './constants'

type OfferBaseProps = StackProps

export const OfferBase = ({ children, ...props }: OfferBaseProps) => {
  return (
    <VStack
      w="full"
      alignItems="stretch"
      bg={BG_GRADIENT}
      borderRadius={16}
      p="2px"
      shadow="md"
      {...props}>
      <Box bg="background.default" borderRadius={16} py={3} px={4} position="relative" w="auto">
        {children}
      </Box>
    </VStack>
  )
}
