import { type ConsentState, type DataCollectionConfig, type IConsentManager } from '../typings'

export class ConsentManager implements IConsentManager {
  private readonly config: DataCollectionConfig
  private currentConsentState: ConsentState | null = null
  private consentUpdateCallbacks: ((state: ConsentState) => void)[] = []
  private cookiebotListeners: (() => void)[] = []
  private initialized = false
  private listenersInitialized = false

  constructor(config: DataCollectionConfig) {
    this.config = config
    this.initialize()
  }

  private initialize(): void {
    if (this.initialized) {
      return
    }

    this.currentConsentState = {
      // @ts-expect-error -- Making sure this is set
      analytics: false,
      // @ts-expect-error -- Making sure this is set
      marketing: false,
      // @ts-expect-error -- Making sure this is set
      preferences: false,
      ...this.config.consentSettings.defaultConsentState,
      client_timestamp: Date.now()
    }

    if (this.config.managementMode === 'managed') {
      this.initializeManagedConsent()
    } else {
      this.initializeCustomerConsent()
    }

    this.initialized = true

    if (this.config.isDebugMode) {
      // eslint-disable-next-line no-console -- We want to log
      console.log('Initialized ConsentManager')
    }
  }

  private initializeCookiebotListeners(): void {
    if (this.listenersInitialized) return

    window.addEventListener('CookiebotOnConsentReady', this.handleCookiebot)
    window.addEventListener('CookiebotOnAccept', this.handleCookiebot)
    window.addEventListener('CookiebotOnDecline', this.handleCookiebot)
    this.cookiebotListeners.push(this.handleCookiebot)

    this.listenersInitialized = true
  }

  private initializeManagedConsent(): void {
    if (!this.config.cookiebotConfig.containerId) {
      throw new Error('Cookiebot container ID is required for managed consent')
    }

    const domain = window.location.hostname
    if (domain === 'localhost' || domain === '127.0.0.1') {
      if (this.config.isDebugMode) {
        // eslint-disable-next-line no-console -- Log expected
        console.log('Running on localhost - ensure domain is configured in Cookiebot dashboard')
      }
    }

    const config = this.config

    const loadCookiebot = () => {
      if (!config.cookiebotConfig.containerId) return

      if (!document.getElementById('Cookiebot')) {
        const script = document.createElement('script')
        script.id = 'Cookiebot'
        script.src = `https://consent.cookiebot.com/uc.js`
        script.setAttribute('data-cbid', config.cookiebotConfig.containerId)
        script.setAttribute('data-blockingmode', 'auto')
        script.type = 'text/javascript'
        script.async = true

        script.setAttribute('data-track-enabled', 'false')

        script.onerror = (e) => {
          if (this.config.isDebugMode) {
            // eslint-disable-next-line no-console -- Log expected
            console.error('Failed to load Cookiebot script', e)
          }
        }

        document.head.appendChild(script)
      }

      // Add Google Consent Mode default configuration
      this.initializeGoogleConsent()

      if (window.Cookiebot?.consent) {
        this.handleCookiebot()
      } else {
        this.initializeCookiebotListeners()
      }
    }

    // Delay injection until after hydration
    if (document.readyState === 'complete') {
      loadCookiebot()
    } else {
      window.addEventListener('load', loadCookiebot) // 👈 Aligns with afterInteractive
    }
  }

  // Add this new method
  private initializeGoogleConsent(): void {
    const script = document.createElement('script')
    script.setAttribute('data-cookieconsent', 'ignore')
    script.textContent = `
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag("consent", "default", {
      ad_personalization: "denied",
      ad_storage: "denied",
      ad_user_data: "denied",
      analytics_storage: "denied",
      functionality_storage: "denied",
      personalization_storage: "denied",
      security_storage: "granted",
      wait_for_update: 500,
    });
    gtag("set", "ads_data_redaction", true);
    gtag("set", "url_passthrough", false);
  `
    document.head.insertBefore(script, document.head.firstChild)
  }

  private handleCookiebot = () => {
    if (window.Cookiebot) {
      this.updateConsentState({
        analytics: window.Cookiebot.consent.statistics,
        marketing: window.Cookiebot.consent.marketing,
        preferences: window.Cookiebot.consent.preferences,
        client_timestamp: Date.now()
      })
    }
  }

  private handleConsentUpdate = ((event: CustomEvent<ConsentState>) => {
    try {
      if (!event?.detail) throw new Error('Invalid consent update event')
      this.updateConsentState(event.detail)
    } catch (err) {
      // eslint-disable-next-line no-console -- We want to log this
      console.error('Failed to process consent update:', err)
    }
  }) as EventListener

  private initializeCustomerConsent(): void {
    if (this.config.isDebugMode) {
      // eslint-disable-next-line no-console -- We want to log
      console.log('Self customer consent enabled.')
    }

    if (window.Cookiebot?.consent) {
      this.handleCookiebot()
    } else {
      this.initializeCookiebotListeners()
      window.addEventListener('consentUpdate', this.handleConsentUpdate)
      this.cookiebotListeners.push(this.handleConsentUpdate as () => void)
    }
  }

  public getCurrentConsentState(): ConsentState {
    if (!this.initialized) {
      throw new Error('ConsentManager not initialized')
    }
    if (!this.currentConsentState) {
      throw new Error('Consent state not initialized')
    }
    return { ...this.currentConsentState }
  }

  private updateConsentState(newState: ConsentState): void {
    if (JSON.stringify(this.currentConsentState) === JSON.stringify(newState)) {
      return
    }
    this.currentConsentState = newState

    if (this.config.isDebugMode) {
      // eslint-disable-next-line no-console -- We want to log
      console.log('Consent state updated:', newState, '')
    }

    this.notifyConsentUpdate(newState)
  }

  public onConsentUpdate = (callback: (state: ConsentState) => void): void => {
    this.consentUpdateCallbacks.push(callback)
  }

  private notifyConsentUpdate(state: ConsentState): void {
    this.consentUpdateCallbacks.forEach((callback) => callback(state))
  }

  public removeConsentUpdateCallback = (callback: (state: ConsentState) => void): void => {
    this.consentUpdateCallbacks = this.consentUpdateCallbacks.filter((cb) => cb !== callback)
  }

  public destroy(): void {
    this.consentUpdateCallbacks = []

    this.cookiebotListeners.forEach((listener) => {
      window.removeEventListener('CookiebotOnConsentReady', listener)
      window.removeEventListener('CookiebotOnAccept', listener)
      window.removeEventListener('CookiebotOnDecline', listener)
      window.removeEventListener('consentUpdate', listener)
    })

    this.cookiebotListeners = []
    this.listenersInitialized = false
    this.initialized = false
    this.currentConsentState = null

    if (this.config.isDebugMode) {
      // eslint-disable-next-line no-console -- We want to log
      console.log('Destroyed ConsentManager')
    }
  }

  public loadCookieDeclaration(targetElementId: string): void {
    if (!document.getElementById('CookieDeclaration')) {
      const script = document.createElement('script')
      script.id = 'CookieDeclaration'
      script.src = `https://consent.cookiebot.com/${this.config.cookiebotConfig.containerId}/cd.js`
      script.async = true

      script.onerror = () => {
        if (this.config.isDebugMode) {
          // eslint-disable-next-line no-console -- We want to log
          console.error('Failed to load Cookie Declaration script')
        }
      }

      const targetElement = document.getElementById(targetElementId)
      if (targetElement) {
        targetElement.appendChild(script)
      } else if (this.config.isDebugMode) {
        // eslint-disable-next-line no-console -- We want to log
        console.warn(`Target element with id '${targetElementId}' not found`)
      }
    }
  }
}
