import { useCallback, useRef } from 'react'

class PromiseQueue {
  queue: Promise<any> = Promise.resolve(true)

  add(operation: () => Promise<any>) {
    return new Promise<any>((resolve, reject) => {
      this.queue = this.queue.then(operation).then(resolve).catch(reject)
    })
  }

  reset() {
    this.queue = Promise.resolve(true)
  }
}

export const usePromiseQueue = () => {
  const queue = useRef(new PromiseQueue())

  const addToQueue = useCallback((cb: () => Promise<any>) => {
    return queue.current.add(cb)
  }, [])

  const resetQueue = useCallback(() => {
    return queue.current.reset()
  }, [])

  return {
    addToQueue,
    resetQueue
  }
}
