import {
  Box,
  Divider,
  Hide,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack
} from '@chakra-ui/react'
import { IoChatboxOutline, IoPricetagOutline } from 'react-icons/io5'
import { CounterBadge } from 'ui'
import { type ReactElement, type ReactNode, useEffect, useMemo } from 'react'
import type { AihubTabId } from 'ecosystem'
import GrandIconButton from 'ui/common/GrandIconButton'
import { overrideText } from 'ui/lib/overrides'
import { BiCollapseAlt, BiExpandAlt } from 'react-icons/bi'
import { CgClose } from 'react-icons/cg'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import dynamic from 'next/dynamic'
import { AihubOffers } from '../modules/aihubOffersModule/components/AihubOffers'
import { AihubAssistant } from '../modules/aihubAssistantModule/components'
import { useAihubContext } from '../aihubContext'
import { type AssistantTextOverrides } from '../../assistant'
import { useAihubState } from './useAihubState'
import { useAihubUnreadCount } from './useAihubUnreadCount'

const NotificationsBanner = dynamic(
  () => import('../../notifications').then((r) => r.NotificationsBanner),
  { ssr: false }
)

interface IAihubTab {
  id: AihubTabId
  tab: {
    title: ReactNode
    icon: ReactElement
    counter?: number
  }
  content: ReactNode
}

export const AihubMain = () => {
  const overrides = useOverridesContext<keyof AssistantTextOverrides>()
  const { assistant, offers, handleAddToCart, cartState } = useAihubContext()
  const { unreadOffersCount, unreadAssistantCount } = useAihubUnreadCount()
  const { setActiveTabId, activeTabId, toggleSize, isExpanded, closeAihub } = useAihubState()

  const tabs = useMemo(() => {
    const _tabs: IAihubTab[] = []

    if (assistant?.isEnabled) {
      _tabs.push({
        id: 'chat',
        tab: {
          title: 'Chat',
          icon: <IoChatboxOutline />,
          counter: unreadAssistantCount
        },
        content: <AihubAssistant paths={assistant?.paths} components={assistant?.components} />
      })
    }

    if (offers?.isEnabled) {
      _tabs.push({
        id: 'offers',
        tab: {
          title: 'Deals',
          icon: <IoPricetagOutline />,
          counter: unreadOffersCount
        },
        content: (
          <AihubOffers
            paths={offers?.paths}
            handleAddToCart={handleAddToCart}
            cartState={cartState}
          />
        )
      })
    }

    return _tabs
  }, [
    assistant?.components,
    assistant?.isEnabled,
    assistant?.paths,
    cartState,
    handleAddToCart,
    offers?.isEnabled,
    offers?.paths,
    unreadAssistantCount,
    unreadOffersCount
  ])

  useEffect(() => {
    if (!activeTabId && tabs[0]?.id) {
      setActiveTabId(tabs[0].id)
    }
  }, [activeTabId, setActiveTabId, tabs])

  const currentTabIndex = useMemo(() => {
    if (!activeTabId) {
      return 0
    }

    return tabs.findIndex(({ id }) => id === activeTabId)
  }, [activeTabId, tabs])

  const onChangeTab = (index: number) => {
    const tabId = tabs[index]?.id

    setActiveTabId(tabId)
  }

  return (
    <VStack gap={0} alignItems="stretch" h="100%">
      <NotificationsBanner borderTopEndRadius={{ base: 'none', sm: '3xl' }} />
      <Tabs
        display="flex"
        flexDirection="column"
        h="100%"
        w="100%"
        gap={0}
        alignItems="stretch"
        lazyBehavior="keepMounted"
        isLazy={false}
        index={currentTabIndex}
        onChange={onChangeTab}>
        <HStack w="full" gap={0} justifyContent="stretch">
          <TabList borderBottomWidth="2px" flex={1} w="full">
            {tabs.map(({ tab, id }) => (
              <Tab key={id} gap={4} px={3} _selected={{ borderColor: 'primary' }} flex={1}>
                <HStack>
                  {tab.icon}
                  <Box>{tab.title}</Box>
                </HStack>

                {!!tab.counter && <CounterBadge value={tab.counter} boxShadow="none" size="xs" />}
              </Tab>
            ))}
          </TabList>

          <Divider orientation="vertical" m={0} h="60%" ml="-1px" />

          <HStack gap={0} px={2} borderBottomWidth="2px" borderColor="inherit">
            <Hide below="sm">
              <GrandIconButton
                aria-label={
                  isExpanded
                    ? overrideText('Krympa', overrides?.assistantShrink)
                    : overrideText('Expandera', overrides?.assistantExpand)
                }
                fontSize="xl"
                icon={isExpanded ? <BiCollapseAlt /> : <BiExpandAlt />}
                minW={undefined}
                onClick={toggleSize}
                shadow="none"
                tooltip={
                  isExpanded
                    ? overrideText('Krympa', overrides?.assistantShrink)
                    : overrideText('Expandera', overrides?.assistantExpand)
                }
                variant="ghost"
                tabIndex={-1}
              />
            </Hide>

            <GrandIconButton
              aria-label={overrideText('Minimera', overrides?.assistantMinimize)}
              fontSize="lg"
              icon={<CgClose />}
              minW={undefined}
              onClick={closeAihub}
              shadow="none"
              tooltip={overrideText('Minimera', overrides?.assistantMinimize)}
              variant="ghost"
              tabIndex={-1}
            />
          </HStack>
        </HStack>

        <TabPanels display="flex" flexDirection="column" flex="1" w="100%">
          {tabs.map(({ id, content }) => (
            <TabPanel key={id} p={0} display="flex" flexDirection="column" flex="1" w="100%">
              {content}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </VStack>
  )
}
