import { createContext } from 'ui/lib/react-utils'
import { type PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import { aihubStorage, createUuid } from 'shared-utils'
import { type AiOfferAnalyticsRequestDTO, type CartItem, type ProductLightDTO } from 'ecosystem'
import { useGenericRequest } from 'api'
import { useRefUpdated } from 'ui/hooks/useRefUpdated'
import { useGTMContext } from '../data-collection'
import { useNotificationsContext } from '../notifications'

interface AihubAnalyticsProviderPureState {
  userSessionId: string | null
  visitProductPage: (product: ProductLightDTO) => void
}

const [AihubAnalyticsProviderPure, useAihubAnalyticsContext] =
  createContext<AihubAnalyticsProviderPureState>({
    name: 'AihubAnalytics'
  })

export { useAihubAnalyticsContext }

type AihubAnalyticsProviderProps = PropsWithChildren & {
  skipConsent?: boolean
}

export const AihubAnalyticsProvider = ({ children, skipConsent }: AihubAnalyticsProviderProps) => {
  const { token } = useNotificationsContext()
  const { consentManager, isInitialized } = useGTMContext()
  const [userSessionId, setUserSessionId] = useState<string | null>(null)
  const { fetchNextRoute: fetchAihubAnalytic } = useGenericRequest<void>()

  const applyNewUserSessionId = useCallback(() => {
    const id = aihubStorage.getAnalyticUserId() ?? createUuid()
    setUserSessionId(id)
    aihubStorage.setAnalyticUserId(id)
  }, [])

  useEffect(() => {
    if (skipConsent) {
      applyNewUserSessionId()
    }
  }, [applyNewUserSessionId, skipConsent])

  useEffect(() => {
    if (!isInitialized || skipConsent) {
      return
    }

    const removeUserSessionId = () => {
      setUserSessionId(null)
      aihubStorage.removeAnalyticUserId()
    }

    const currentConsent = consentManager?.getCurrentConsentState()

    if (currentConsent?.analytics) {
      applyNewUserSessionId()
    }

    consentManager?.onConsentUpdate((state) => {
      if (state?.analytics) {
        applyNewUserSessionId()
      } else {
        removeUserSessionId()
      }
    })
  }, [applyNewUserSessionId, consentManager, isInitialized, skipConsent])

  const sendEvent = useCallback(
    (params: Omit<AiOfferAnalyticsRequestDTO, 'token' | 'userSessionId'>) => {
      if (!userSessionId) {
        return
      }

      fetchAihubAnalytic('/api/ai-offers/send-analytics', {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          token,
          userSessionId,
          ...params
        } as AiOfferAnalyticsRequestDTO)
      })
    },
    [fetchAihubAnalytic, token, userSessionId]
  )

  const visitProductPage = useCallback(
    (product: ProductLightDTO) => {
      sendEvent({
        event: 'visitProductPage',
        data: {
          product: {
            id: product.id,
            slug: product.slug
          }
        }
      })
    },
    [sendEvent]
  )

  return (
    <AihubAnalyticsProviderPure
      value={{
        userSessionId,
        visitProductPage
      }}>
      {children}
    </AihubAnalyticsProviderPure>
  )
}

/**
 * Tracks a user's visit to a product page and determines whether to send analytics data.
 *
 * - If a user stays on the product page for more than 20 seconds, the visit is considered significant.
 * - If the user leaves after this time, analytics data is sent to track engagement.
 * - However, if the product is added to the cart, the timer is cleared, and analytics are not sent,
 *   avoiding unnecessary discount triggers when the user is already interested in purchasing.
 */
export const useAihubAnalyticsVisitPageView = ({
  product,
  cartItems
}: {
  product: ProductLightDTO
  cartItems: CartItem[]
}) => {
  const { visitProductPage } = useAihubAnalyticsContext()
  const timerId = useRef(NaN)
  const needToSendAnalytics = useRef(false)
  const visitProductPageRef = useRefUpdated(visitProductPage)

  useEffect(() => {
    timerId.current = window.setTimeout(() => {
      needToSendAnalytics.current = true
    }, 20000)

    return () => {
      clearTimeout(timerId.current)

      if (needToSendAnalytics.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps -- function should be updated when it's called
        visitProductPageRef.current(product)
      }
    }
  }, [product, visitProductPageRef])

  useEffect(() => {
    const isProductInCart = cartItems.some((item) => {
      if (item.type === 'SINGLE') {
        return item.meta.singleType.product.id === product.id
      }

      return item.meta.groupType.items.some((p) => p.product.id === product.id)
    })

    if (isProductInCart) {
      clearTimeout(timerId.current)
      needToSendAnalytics.current = false
    }
  }, [cartItems, product.id])
}
