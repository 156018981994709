import { type AiOfferDTO, type AiOfferProductDTO, type ICartItemSingle } from 'ecosystem'
import { Box, Button, VStack } from '@chakra-ui/react'
import { useCallback, useMemo, useState } from 'react'
import lodashOmit from 'lodash/omit'
import { useAcceptAiOfferMutation } from 'shared-redux/state'
import { useAiOffersContext } from '../../aihubOffersContext'
import { OfferBase } from './OfferBase'
import { OfferHeader } from './OfferHeader'
import { OfferTotal } from './OfferTotal'
import { ProductSelector } from './ProductSelector'
import { OfferProduct } from './OfferProduct'

interface OfferBuyOneProps {
  offer: AiOfferDTO
}

export const OfferBuyOne = ({ offer }: OfferBuyOneProps) => {
  const { handleAddToCart, cartState } = useAiOffersContext()
  const [selectedProduct, setSelectedProduct] = useState<AiOfferProductDTO | null | undefined>(
    () => {
      return offer.products.length === 1 ? offer.products[0] : null
    }
  )
  const [acceptAiOfferMutation, { isLoading: isAcceptingAiOffer }] = useAcceptAiOfferMutation()

  const isUsed = useMemo(
    () => cartState.cartItems.some((cartItem) => cartItem.offerInfo?.id === offer.id),
    [cartState.cartItems, offer]
  )

  const { total, totalOriginal } = useMemo(() => {
    return {
      total: selectedProduct ? (selectedProduct.quantity ?? 1) * selectedProduct.product.price : 0,
      totalOriginal: selectedProduct
        ? (selectedProduct.quantity ?? 1) * selectedProduct.product.originalPrice
        : 0
    }
  }, [selectedProduct])

  const onSubmit = useCallback(() => {
    if (!selectedProduct?.product) {
      return
    }

    if (!offer.accepted) {
      acceptAiOfferMutation({ offerId: offer.id, sessionId: offer.sessionId })
    }

    const cartItem = {
      id: selectedProduct.product.id,
      qty: 1,
      type: 'SINGLE',
      meta: {
        singleType: {
          product: selectedProduct.product
        }
      },
      offerInfo: lodashOmit(offer, ['products'])
    } satisfies ICartItemSingle

    handleAddToCart(cartItem)
  }, [acceptAiOfferMutation, handleAddToCart, offer, selectedProduct?.product])

  return (
    <OfferBase>
      <VStack w="full" alignItems="strech" gap={4}>
        <OfferHeader offer={offer} />

        {offer.products.length === 1 ? (
          <OfferProduct product={offer.products[0]} />
        ) : (
          <VStack w="full" alignItems="strech" gap={2}>
            <Box fontSize="sm" color="text.mild">
              Pick one product you want to buy:
            </Box>
            <ProductSelector
              w="full"
              alignItems="strech"
              products={offer.products}
              onChange={(product?: AiOfferProductDTO) => {
                setSelectedProduct(product)
              }}
            />
          </VStack>
        )}

        <OfferTotal total={total} totalOriginal={totalOriginal} />

        <Button
          isDisabled={isUsed || !selectedProduct}
          isLoading={isAcceptingAiOffer}
          onClick={onSubmit}>
          {isUsed ? 'Accepted' : 'Add to the cart'}
        </Button>
      </VStack>
    </OfferBase>
  )
}
