import { useDispatch, useSelector } from 'shared-redux'
import {
  selectAihubActiveTabId,
  selectAihubIsOpen,
  selectAihubSizeState,
  setAihubActiveTabId,
  setAihubIsOpen,
  setAihubSizeState
} from 'shared-redux/state'
import { useCallback } from 'react'
import { aihubStorage } from 'shared-utils'
import { type AihubTabId } from 'ecosystem'

export const useAihubState = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(selectAihubIsOpen)
  const isExpanded = useSelector(selectAihubSizeState) === 'MAX'
  const activeTabId = useSelector(selectAihubActiveTabId)

  const openAihub = useCallback(() => {
    dispatch(setAihubIsOpen(true))
  }, [dispatch])

  const closeAihub = useCallback(() => {
    dispatch(setAihubIsOpen(false))
  }, [dispatch])

  const toggleAihub = useCallback(() => {
    if (isOpen) {
      closeAihub()
    } else {
      openAihub()
    }
  }, [closeAihub, isOpen, openAihub])

  const expandSize = useCallback(() => {
    dispatch(setAihubSizeState('MAX'))
    aihubStorage.setSizeState('MAX')
  }, [dispatch])

  const shrinkSize = useCallback(() => {
    dispatch(setAihubSizeState('MIN'))
    aihubStorage.setSizeState('MIN')
  }, [dispatch])

  const toggleSize = useCallback(() => {
    if (isExpanded) {
      shrinkSize()
    } else {
      expandSize()
    }
  }, [expandSize, isExpanded, shrinkSize])

  const setActiveTabId = useCallback(
    (tabId: AihubTabId) => {
      dispatch(setAihubActiveTabId(tabId))
    },
    [dispatch]
  )

  return {
    isOpen,
    openAihub,
    closeAihub,
    toggleAihub,
    isExpanded,
    toggleSize,
    expandSize,
    shrinkSize,
    activeTabId,
    setActiveTabId
  }
}
