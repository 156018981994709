import { type FC, type ReactNode } from 'react'
import { Box, Center, Flex, HStack, Icon, type StackProps, Text, VStack } from '@chakra-ui/react'
import type { AssistantMessageFE, AssistantMessageProductDTO } from 'ecosystem'
import { IoMdAlert } from 'react-icons/io'
import GrandText from 'ui/common/GrandText'
import GrandTooltip from 'ui/common/GrandTooltip'
import { PiSpeakerSimpleHighDuotone } from 'react-icons/pi'
import dynamic from 'next/dynamic'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { overrideText } from 'ui/lib/overrides'
import { type AssistantTextOverrides, ConversationalModeStatus } from '../types'
import { useAssistantContext } from './asssistantContext'
import { Avatar } from './Avatar'
import useSpeechAnimationSrc from './useSpeechAnimationSrc'
import { BG_GRADIENT } from './constants'

const LottiePlayer = dynamic(
  () => import('@lottiefiles/react-lottie-player').then((mod) => mod.Player),
  {
    ssr: false
  }
)

export interface ChatMessageProps extends StackProps {
  msg: AssistantMessageFE
  renderProductsComponent: (products: AssistantMessageProductDTO[] | undefined) => ReactNode
  onSpeak: (text: AssistantMessageFE) => Promise<void> | void
  isLoadingSpeech: boolean
  isPlayingSpeech: boolean
  error?: string
}

const ChatMessage: FC<ChatMessageProps> = ({
  onSpeak,
  renderProductsComponent,
  msg,
  error,
  isLoadingSpeech,
  isPlayingSpeech,
  ...props
}) => {
  const overrides = useOverridesContext<keyof AssistantTextOverrides>()
  const { settings, conversationalMode } = useAssistantContext()
  const isBot = msg.role === 'assistant'
  const utcDate = new Date(msg.created)
  const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000)
  const formattedMsgCreation = localDate.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  })

  const isConversationalModeOff =
    conversationalMode.status === ConversationalModeStatus.Inactive ||
    conversationalMode.status === ConversationalModeStatus.NotInitialized

  const animationSrc = useSpeechAnimationSrc({
    isLoading: isLoadingSpeech,
    isPlayingThumb: isPlayingSpeech
  })

  const renderSpeechIcon = () => {
    if (animationSrc) {
      return (
        <Box boxSize={6}>
          <LottiePlayer
            autoplay
            loop
            src={animationSrc}
            style={{ height: '100%', width: '100%' }}
          />
        </Box>
      )
    }

    return (
      <Icon
        className="ai-widget__message-speak"
        cursor={isConversationalModeOff ? 'pointer' : 'default'}
        flexShrink={1}
        as={PiSpeakerSimpleHighDuotone}
        boxSize={6}
        color={isConversationalModeOff ? 'gray' : 'gray.200'}
        onClick={() => {
          if (isConversationalModeOff) {
            void onSpeak(msg)
          }
        }}
      />
    )
  }

  if (isBot) {
    return (
      <HStack
        alignItems="flex-start"
        alignSelf="start"
        className="ai-widget__message"
        data-message-id={msg.id}
        gap={1}
        maxWidth="100%"
        width="full"
        {...props}>
        <Center borderRadius="50%" position="sticky" top={0}>
          <Avatar
            w="20px"
            h="20px"
            imageProps={{
              src: settings?.imgUrl,
              objectFit: 'contain',
              w: 'full',
              h: 'full'
            }}
          />
        </Center>

        <VStack alignItems="flex-start" flex={1} spacing={0}>
          <Box position="relative" w="full">
            <Box
              position="relative"
              borderRadius={16}
              borderTopLeftRadius={2}
              bg={BG_GRADIENT}
              p="2px"
              shadow="md">
              <Box
                bg="background.default"
                borderRadius={16}
                borderTopLeftRadius={2}
                py={3}
                px={4}
                position="relative"
                sx={{
                  zIndex: 1
                }}>
                <GrandText
                  bg="transparent"
                  fontSize="sm"
                  fontWeight="normal"
                  w="auto"
                  cursor="default"
                  whiteSpace="break-spaces">
                  {msg.text}
                </GrandText>
              </Box>
            </Box>
          </Box>

          <Text px={2} pt={1} w="full" textAlign="right" fontSize="xs" color="gray.400">
            {formattedMsgCreation}
          </Text>
          {renderProductsComponent(msg.products)}
        </VStack>

        {renderSpeechIcon()}
      </HStack>
    )
  }

  return (
    <HStack alignSelf="end" maxWidth="calc(90% - 45px)" {...props}>
      {!!error && (
        <GrandTooltip color="red" label={error}>
          <Box color="red">
            <IoMdAlert />
          </Box>
        </GrandTooltip>
      )}

      <VStack alignItems="flex-start" flex={1} spacing={0}>
        <Flex
          bg="background.surface"
          borderStyle="solid"
          borderColor="white"
          borderWidth={2}
          borderRadius={16}
          borderBottomRightRadius={2}
          px={4}
          py={3}
          shadow="md">
          <GrandText fontSize="sm" fontWeight="normal" w="full">
            {msg.text}
          </GrandText>
        </Flex>
        {!error ? (
          <Text px={2} pt={1} w="full" textAlign="right" fontSize="xs" color="gray.400">
            {overrideText('Skickat', overrides?.assistantStatusSent)}
          </Text>
        ) : null}
      </VStack>
    </HStack>
  )
}

export default ChatMessage
