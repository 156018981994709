import { AnimatePresence, MotionConfig } from 'framer-motion'
import { IconButton, type IconButtonProps } from '@chakra-ui/react'
import { type WithOptional } from 'ecosystem'
import { CounterBadge, MotionChakraBox } from 'ui'
import { AihubStarIcon } from './AihubStarIcon'
import { useAihubUnreadCount } from './useAihubUnreadCount'
import { useAihubState } from './useAihubState'

type AihubMobileTriggerProps = WithOptional<IconButtonProps, 'aria-label'>

const iconVariants = {
  hidden: { scale: 0, rotate: -180, opacity: 0 },
  visible: { scale: 1, rotate: 0, opacity: 1 },
  exit: { scale: 0, rotate: 180, opacity: 0 }
}

export const AihubMobileTrigger = ({
  'aria-label': ariaLabel,
  ...props
}: AihubMobileTriggerProps) => {
  const { toggleAihub, isOpen } = useAihubState()
  const { unreadTotalCount } = useAihubUnreadCount()

  return (
    <IconButton
      display={{ base: 'inline-flex', md: 'none' }}
      aria-label={ariaLabel ?? `${isOpen ? 'open' : 'close'} aihub`}
      onClick={toggleAihub}
      size="sm"
      fontSize="md"
      background={isOpen ? 'primary' : 'transparent'}
      color={isOpen ? 'text.light' : 'text.default'}
      variant="outline"
      borderRadius="xl"
      height={10}
      p={3}
      {...props}>
      <MotionConfig transition={{ type: 'spring', bounce: 0.35, duration: 0.8 }}>
        {isOpen ? (
          <AnimatePresence initial={false} mode="popLayout">
            <MotionChakraBox
              key="close"
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={iconVariants}>
              {/*<IoSparkles />*/}
              <AihubStarIcon width={22} height="22" minW="22px" />
            </MotionChakraBox>
          </AnimatePresence>
        ) : (
          <>
            {!!unreadTotalCount && (
              <CounterBadge position="absolute" top="-5px" right="-7px" value={unreadTotalCount} />
            )}

            <AnimatePresence initial={false} mode="popLayout">
              <MotionChakraBox
                key="sparkles"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={iconVariants}>
                {/*<IoSparklesOutline />*/}
                <AihubStarIcon width={22} height="22" minW="22px" />
              </MotionChakraBox>
            </AnimatePresence>
          </>
        )}
      </MotionConfig>
    </IconButton>
  )
}
