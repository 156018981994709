import { type StackProps, useRadioGroup, VStack } from '@chakra-ui/react'
import { type AiOfferProductDTO } from 'ecosystem'
import { ProductSelectionItem } from './ProductSelectionItem'

export interface ProductSelectorPropsBase {
  products: AiOfferProductDTO[]
  initialValue?: AiOfferProductDTO
  onChange: (product?: AiOfferProductDTO) => void
}
export type PlaceSelectorProps = Omit<StackProps, 'onChange'> & ProductSelectorPropsBase

export const ProductSelector = ({
  products,
  onChange,
  initialValue,
  ...props
}: PlaceSelectorProps) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'selectProduct',
    onChange: (value) => {
      const selectedPosition = products.find((item) => item.product.id === value)

      onChange(selectedPosition)
    },
    ...(initialValue ? { defaultValue: initialValue.product.id } : {})
  })

  const group = getRootProps()

  return (
    <VStack {...props} {...group}>
      {products.map((item) => (
        <ProductSelectionItem
          key={item.product.id}
          radioProps={getRadioProps({ value: item.product.id })}
          product={item}
        />
      ))}
    </VStack>
  )
}
