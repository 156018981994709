import { type FC, type ReactNode, useEffect, useRef, useState } from 'react'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Flex,
  HStack,
  type StackProps,
  Text,
  VStack
} from '@chakra-ui/react'
import { IoReload } from 'react-icons/io5'
import { AnimatePresence, motion } from 'framer-motion'
import GrandIconButton from 'ui/common/GrandIconButton'
import type { AssistantMessageProductDTO, Option } from 'ecosystem'
import BouncingDots from './BouncingDots'
import { ChatMessage } from './ChatMessage'
import { useAssistantContext } from './asssistantContext'
import { getMessagesElement, waitForElement } from './utils'

type ChatMessagesProps = StackProps & {
  renderProductsComponent: (products: AssistantMessageProductDTO[] | undefined) => ReactNode
  onSpeak: (text: string) => Promise<void> | void
  isLoadingSpeech: boolean
  isPlayingSpeech: boolean
  statusLabel: string
}

const ChatMessages: FC<ChatMessagesProps> = ({
  onSpeak,
  renderProductsComponent,
  sx,
  isLoadingSpeech,
  isPlayingSpeech,
  statusLabel,
  ...props
}) => {
  const { history, errors, initError, isLoading, reinit, isInView, newMessages } =
    useAssistantContext()
  const [currentSpeechMsgId, setCurrentSpeechMsgId] = useState<Option<string>>(null)
  const bottomRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isInView && history && !newMessages?.length) {
      // Scroll to the bottom when module is in view
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [history, isInView, newMessages?.length])

  useEffect(() => {
    if (!newMessages?.length || !isInView) {
      return
    }

    waitForElement('#ai-widget__loader', (loaderElement) => {
      if (loaderElement) {
        loaderElement.scrollIntoView({ behavior: 'smooth' })
      }
    })

    const { id, role } = newMessages[newMessages.length - 1]

    if (role === 'client') {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
      return
    }

    const messageElement = getMessagesElement()?.querySelector(`[data-message-id="${id}"]`)

    if (messageElement) {
      messageElement.scrollIntoView({ behavior: 'smooth' })
    }
  }, [isInView, newMessages])

  useEffect(() => {
    waitForElement('#ai-widget__status-label', (loaderElement) => {
      if (loaderElement) {
        loaderElement.scrollIntoView({ behavior: 'smooth' })
      }
    })
  }, [statusLabel])

  return (
    <VStack
      gap={2}
      className="ai-widget__messages"
      id="assistant-messages"
      overflowX="hidden"
      overflowY="auto"
      overscrollBehaviorY="contain"
      p={4}
      py={4}
      sx={{
        scrollbarWidth: 'none',
        ...sx
      }}
      {...props}>
      <VStack flex={1} gap={4} w="full">
        <AnimatePresence>
          {history?.map((msg) => (
            <motion.div
              key={msg.id}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              transition={{ duration: 0.5 }}
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: msg.role === 'assistant' ? 'flex-start' : 'flex-end'
              }}>
              <ChatMessage
                _first={{ pt: 0 }}
                error={errors[msg.id]}
                msg={msg}
                pt={4}
                renderProductsComponent={renderProductsComponent}
                onSpeak={async (m) => {
                  setCurrentSpeechMsgId(m.id)
                  await onSpeak(m.text)
                }}
                isLoadingSpeech={isLoadingSpeech && msg.id === currentSpeechMsgId}
                isPlayingSpeech={isPlayingSpeech && msg.id === currentSpeechMsgId}
              />
            </motion.div>
          ))}
        </AnimatePresence>
      </VStack>

      {isLoading && (
        <Flex
          id="ai-widget__loader"
          align="center"
          flex={0}
          justify="center"
          position="relative"
          pt={4}
          w="full">
          <BouncingDots />
        </Flex>
      )}

      {statusLabel && (
        <Text
          id="ai-widget__status-label"
          px={4}
          fontStyle="italic"
          fontSize="sm"
          color="gray.400"
          textAlign="center"
          bg="transparent"
          w="full">
          {statusLabel}
        </Text>
      )}

      {!!initError && (
        <Alert borderRadius="md" status="error">
          <HStack justifyContent="space-between" w="100%">
            <HStack>
              <AlertIcon />
              <AlertDescription>{initError}</AlertDescription>
            </HStack>

            <GrandIconButton
              aria-label="Reload assistant"
              fontSize="sm"
              icon={<IoReload />}
              minW={undefined}
              onClick={reinit}
              shadow="none"
              size="sm"
              tooltip="Retry"
              variant="ghost"
            />
          </HStack>
        </Alert>
      )}
      <div ref={bottomRef} />
    </VStack>
  )
}

export default ChatMessages
