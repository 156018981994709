import { useEffect, useRef } from 'react'
import { type ConversationalModeStatus } from '../types'

export const useStatusRef = (status: ConversationalModeStatus) => {
  const statusRef = useRef(status)

  useEffect(() => {
    statusRef.current = status
  }, [status])

  return statusRef
}
