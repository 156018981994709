import Countdown, { type CountdownProps, type CountdownRendererFn } from 'react-countdown'

type UICountdownProps = CountdownProps

export const UICountdown = ({ ...props }: UICountdownProps) => {
  const customRenderer: CountdownRendererFn = ({ formatted, days, hours, minutes, seconds }) => {
    if (days) {
      return (
        <span>
          {days}d {hours}h {minutes}m {formatted.seconds}s
        </span>
      )
    }

    if (hours) {
      return (
        <span>
          {hours}h {minutes}m {formatted.seconds}s
        </span>
      )
    }

    if (minutes) {
      return (
        <span>
          {minutes}:{formatted.seconds}
        </span>
      )
    }

    if (seconds) {
      return <span>{seconds}</span>
    }

    return null
  }

  return <Countdown renderer={customRenderer} {...props} />
}
