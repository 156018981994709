import { useCallback, useEffect, useRef, useState } from 'react'
import useGenericRequest from 'api/utils/next/useGenericRequest'
import { v4 as uuidv4 } from 'uuid'
import {
  type AssistantMessageFE,
  type AssistantV2ChatRequestDTO,
  type AssistantV2ChatResponseDTO,
  type AssistantV2ThreadResponseDTO,
  Currency
} from 'ecosystem'
import { useDispatch, useSelector } from 'shared-redux'
import {
  addError,
  addMessage,
  readMessage as readAssistantMessage,
  resetChat,
  selectAssistantErrors,
  selectAssistantHistory,
  selectAssistantInitError,
  selectAssistantIsInit,
  selectAssistantNewMessages,
  selectAssistantSessionUserId,
  selectAssistantThreadId,
  setInitError,
  setIsInit,
  setSessionUserId,
  setThreadId
} from 'shared-redux/state'
import { assistantStorage, returnUndefinedIfNullish } from 'shared-utils'
import { useUser } from 'auth'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { overrideText } from 'ui/lib/overrides'
import { usePathnameChanged } from 'ui/hooks'
import { useTextToSpeech } from '../../../../assistant/hooks/useTextToSpeech'
import { useAihubContext } from '../../../aihubContext'
import { type CurrencyOverrides } from '../../../../types'
import { type AssistantTextOverrides } from './types'
import { usePromiseQueue } from './usePromiseQueue'

interface HookProps {
  paths: {
    chat: string
    thread: string
    textToSpeech: string
    products: string
    tags: string
    categories: string
    brands: string
  }
}

export const useAihubAssistant = (props: HookProps) => {
  const overrides = useOverridesContext<keyof (AssistantTextOverrides & CurrencyOverrides)>()
  const dispatch = useDispatch()
  const { paths } = props

  const isInit = useSelector(selectAssistantIsInit)
  const initError = useSelector(selectAssistantInitError)
  const errors = useSelector(selectAssistantErrors)
  const threadId = useSelector(selectAssistantThreadId)
  const sessionUserId = useSelector(selectAssistantSessionUserId)
  const history = useSelector(selectAssistantHistory)
  const newMessages = useSelector(selectAssistantNewMessages)

  const { user, isLoading: isLoadingUser, isValidating: isValidatingUser } = useUser()
  const { newAiOffers } = useAihubContext()
  const { addToQueue, resetQueue } = usePromiseQueue()

  const [inputValue, setInputValue] = useState('')
  const backgroundMessageAbortController = useRef<AbortController | null>(null)
  const backgroundMessagePathChangedTimerId = useRef(NaN)

  const { fetchNextRoute: sendMessageRequest, isLoading: isSendingMessage } =
    useGenericRequest<AssistantV2ChatResponseDTO>()
  const { fetchNextRoute: sendBackgroundMessageRequest } =
    useGenericRequest<AssistantV2ChatResponseDTO>()
  const { fetchNextRoute: getThreadId } = useGenericRequest<AssistantV2ThreadResponseDTO>()

  const _sendForegroundMessage = useCallback(
    (text: string, cbBefore?: (text: string) => Promise<void>) => {
      if (!threadId) {
        return Promise.resolve()
      }

      resetQueue()
      backgroundMessageAbortController.current?.abort('Run priority foreground message request')
      clearTimeout(backgroundMessagePathChangedTimerId.current)

      return addToQueue(async () => {
        let attempt = 0

        await cbBefore?.(text)

        const repeatSendMessage = async (): Promise<AssistantV2ChatResponseDTO> => {
          attempt += 1

          const res = await sendMessageRequest(paths.chat, {
            method: 'POST',
            headers: {
              'Content-type': 'application/json'
            },
            body: JSON.stringify({
              question: text,
              thread_id: threadId
            } as AssistantV2ChatRequestDTO)
          }).unwrap()

          if (!res.response) {
            if (attempt <= 1) {
              return repeatSendMessage()
            }

            throw new Error('RESPONSE_IS_EMPTY')
          }
          return res
        }

        try {
          const res = await repeatSendMessage()
          dispatch(
            addMessage({
              id: uuidv4(),
              text: res.response,
              products: res.products,
              role: 'assistant',
              created: res.created
            })
          )
        } catch (error) {
          throw new Error(`${error}`)
        }
      })
    },
    [addToQueue, dispatch, paths.chat, resetQueue, sendMessageRequest, threadId]
  )

  const sendBackgroundMessage = useCallback(
    (text: string, cbBefore?: (text: string) => Promise<void>) => {
      if (!threadId) {
        throw new Error('No thread id')
      }

      return addToQueue(async () => {
        let attempt = 0

        await cbBefore?.(text)

        const abortController = new AbortController()

        backgroundMessageAbortController.current = abortController

        if (abortController.signal.aborted) {
          throw new Error(abortController.signal.reason)
        }

        const repeatSendMessage = async (): Promise<AssistantV2ChatResponseDTO> => {
          attempt += 1

          const res = await sendBackgroundMessageRequest(paths.chat, {
            signal: abortController.signal,
            method: 'POST',
            headers: {
              'Content-type': 'application/json'
            },
            body: JSON.stringify({
              question: text,
              thread_id: threadId
            } as AssistantV2ChatRequestDTO)
          }).unwrap()

          if (!res.response) {
            if (attempt <= 1) {
              return repeatSendMessage()
            }

            throw new Error('RESPONSE_IS_EMPTY')
          }
          return res
        }

        const res = await repeatSendMessage()
        dispatch(
          addMessage({
            id: uuidv4(),
            text: res.response,
            products: res.products,
            role: 'assistant',
            created: res.created
          })
        )
      })
    },
    [addToQueue, dispatch, paths.chat, sendBackgroundMessageRequest, threadId]
  )

  const sendMessage = useCallback(
    async (text: string) => {
      const id = uuidv4()

      try {
        await _sendForegroundMessage(text, async () => {
          return new Promise((resolve) => {
            const newMessage: AssistantMessageFE = {
              id,
              role: 'client',
              text,
              created: new Date().toISOString()
            }

            dispatch(addMessage(newMessage))
            resolve()
          })
        })
      } catch {
        dispatch(
          addError({
            id,
            error: overrideText('Ej levererad.', overrides?.assistantErrorUndelivered)
          })
        )
      }
    },
    [_sendForegroundMessage, dispatch, overrides?.assistantErrorUndelivered]
  )

  const sendInitRequest = useCallback(async () => {
    try {
      await _sendForegroundMessage(
        user?.customer ? `Hi I'm ${user.customer.firstName}` : '',
        async () => {
          return new Promise((resolve) => {
            dispatch(setIsInit(false))
            dispatch(setInitError(null))
            resolve()
          })
        }
      )

      dispatch(setIsInit(true))
      dispatch(setInitError(null))
    } catch {
      dispatch(
        setInitError(
          overrideText('Tjänsten är inte tillgänglig.', overrides?.assistantErrorServiceUnavailable)
        )
      )
    }
  }, [
    _sendForegroundMessage,
    dispatch,
    user?.customer,
    overrides?.assistantErrorServiceUnavailable
  ])

  const readMessage = useCallback(
    ({ messageId }: { messageId: AssistantMessageFE['id'] }) => {
      dispatch(readAssistantMessage({ messageId }))
    },
    [dispatch]
  )

  const {
    textToSpeech,
    isLoading: isLoadingTextToSpeech,
    isPlaying: isPlayingTextToSpeech,
    error: textToSpeechError,
    audioRef: onSpeakAudioRef
  } = useTextToSpeech(paths.textToSpeech)

  const initAttemptedRef = useRef(false)

  useEffect(() => {
    if (isLoadingUser || isValidatingUser) {
      return
    }

    const customerId = returnUndefinedIfNullish(user?.customerId)

    const abortController = new AbortController()

    const returnFn = () => {
      abortController.abort()
    }

    const getThreadIdFetch = () =>
      getThreadId(`${paths.thread}${customerId ? `?customerId=${customerId}` : ''}`, {
        signal: abortController.signal
      })
        .unwrap()
        .then(({ thread }) => {
          assistantStorage.setThreadId(thread)
          dispatch(setThreadId(thread))
        })

    // customerId = '1' and sessionUserId = null === save history, remove thread id
    // customerId = undefined and sessionUserId = '1' --- means was logout, remove history, remove thread id
    // customerId = '1' and sessionUserId = '2' --- means user was changed, remove history, remove thread id
    // customerId = '1' and sessionUserId = '1' --- do nothing
    // customerId = undefined and sessionUserId = undefined --- do nothing
    if (customerId !== sessionUserId) {
      const isFirstLogin = !!customerId && !sessionUserId

      // first login, don't need to clean history
      if (isFirstLogin) {
        getThreadIdFetch()
          .then(() => {
            if (user?.customer) {
              _sendForegroundMessage(`Hi I'm ${user.customer.firstName}`)
            }
          })
          .catch(() => {
            assistantStorage.removeThreadId()
            dispatch(setThreadId(undefined))
          })
          .finally(() => {
            assistantStorage.setSessionUserId(customerId)
            dispatch(setSessionUserId(customerId))
          })
      } else {
        if (customerId) {
          assistantStorage.setSessionUserId(customerId)
          dispatch(setSessionUserId(customerId))
        } else {
          assistantStorage.removeSessionUserId()
          dispatch(setSessionUserId(undefined))
        }

        assistantStorage.removeHistory()
        assistantStorage.removeThreadId()
        dispatch(resetChat())
      }

      return returnFn
    }

    if (threadId) {
      assistantStorage.setThreadId(threadId)
      return returnFn
    }

    getThreadIdFetch().catch(() => void 0)

    return returnFn
  }, [
    _sendForegroundMessage,
    dispatch,
    getThreadId,
    isLoadingUser,
    isValidatingUser,
    paths.thread,
    threadId,
    sessionUserId,
    user?.customerId,
    user?.customer
  ])

  useEffect(() => {
    if (!threadId || isInit || isLoadingUser || isValidatingUser || initAttemptedRef.current) {
      return
    }

    if (history) {
      dispatch(setIsInit(true))
      return
    }

    initAttemptedRef.current = true
    void sendInitRequest().finally(() => {
      initAttemptedRef.current = false
    })
  }, [dispatch, history, isInit, isLoadingUser, isValidatingUser, sendInitRequest, threadId])

  useEffect(() => {
    if (!isInit || !history) {
      return
    }

    const filtered = history.filter((message) => !errors[message.id])

    assistantStorage.setHistory(filtered.length ? filtered : null)
  }, [errors, history, isInit])

  usePathnameChanged(({ data: { pathname }, meta: { isPathChanged } }) => {
    if (!isInit || !isPathChanged) {
      return
    }

    window.clearTimeout(backgroundMessagePathChangedTimerId.current)

    const isCategoryPage = pathname.startsWith(paths.categories)
    const isBrandPage = pathname.startsWith(paths.brands)
    const isProductPage = pathname.startsWith(paths.products)
    const isTagPage = pathname.startsWith(paths.tags)

    if (!isCategoryPage && !isBrandPage && !isProductPage && !isTagPage) {
      return
    }

    // Send message only if user spent more than 10s on the page
    backgroundMessagePathChangedTimerId.current = window.setTimeout(() => {
      const visitedPages = assistantStorage.getVisitedPages()

      if (
        (isCategoryPage && visitedPages.lastCategoryPage === pathname) ||
        (isBrandPage && visitedPages.lastBrandPage === pathname) ||
        (isTagPage && visitedPages.lastTagPage === pathname) ||
        (isProductPage && visitedPages.lastProductPage === pathname)
      ) {
        return
      }

      void sendBackgroundMessage(`
        I am now visiting page ${pathname}. 
        Just answer me back that you noticed I am interested in this section 
        and you are here to help me finding the right products.
      `)
        .then(() => {
          assistantStorage.setVisitedPages({
            ...assistantStorage.getVisitedPages(),
            ...(isCategoryPage ? { lastCategoryPage: pathname } : {}),
            ...(isBrandPage ? { lastBrandPage: pathname } : {}),
            ...(isTagPage ? { lastTagPage: pathname } : {}),
            ...(isProductPage ? { lastProductPage: pathname } : {})
          })
        })
        .catch(() => {
          /* empty */
        })
    }, 10000)
  })

  useEffect(() => {
    if (!newAiOffers?.length) {
      return
    }

    const items = newAiOffers.map((offer) => {
      return {
        name: offer.name,
        description: offer.description,
        expiresAt: offer.expiresAt,
        products: offer.products.map((product) => ({
          discountedPrice: product.discountedPrice,
          name: product.product.name,
          articleNumber: product.product.articleNumber,
          brand: product.product.brand?.name,
          price: product.product.price
        }))
      }
    })

    const currency = overrideText(Currency.SEK, overrides?.currency)

    sendBackgroundMessage(
      `I've got ${newAiOffers.length} special deals, they are provided in the JSON array below. 
      Answer me back that you just received them (NOT from me), specifying what they are about and what is included, 
      and you are ready to assist me if I need help to know more. Talking about when they expire, 
      just say it's a time limited offer. If you talk about prices, the currency is ${currency}.
       Do not fetch the products from the file, just explain that these deals are available in the "Deals" 
       section above for me to have a look. ${JSON.stringify(items)}`
    ).catch(() => {
      /* empty */
    })
  }, [newAiOffers, overrides?.currency, sendBackgroundMessage])

  return {
    sendMessage,
    readMessage,
    isSendingMessage,
    setInputValue,
    inputValue,
    isInit,
    history,
    newMessages,
    sendInitRequest,
    initError,
    errors,
    textToSpeech,
    isLoadingTextToSpeech,
    isPlayingTextToSpeech,
    textToSpeechError,
    onSpeakAudioRef
  }
}
