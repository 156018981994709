import { useSelector } from 'shared-redux'
import { selectAssistantUnreadCount } from 'shared-redux/state'

export const useAihubAssistantUnreadCount = () => {
  const unreadMessagesCount: number = useSelector(selectAssistantUnreadCount)

  return {
    unreadMessagesCount
  }
}
