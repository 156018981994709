import { notify } from 'ui/lib/notify'
import { useCallback, useEffect } from 'react'
import {
  useGetAiOffersQuery,
  useGetNewAiOffersQuery,
  useLazyGetNewAiOffersQuery
} from 'shared-redux/state'
import { Box, Button, HStack, VStack } from '@chakra-ui/react'
import { IoPricetagOutline } from 'react-icons/io5'
import { useRefUpdated } from 'ui/hooks/useRefUpdated'
import { type NotificationMessage, useNotificationsContext } from '../../notifications'
import { useAihubAnalyticsContext } from '../aihubAnalytics'
import { useAihubState } from './useAihubState'

export const useAihubNotifications = () => {
  const { openAihub, setActiveTabId } = useAihubState()
  const { onMessage, permission, token } = useNotificationsContext()
  const [getNewAiOffers, { data: newAiOffersData }] = useLazyGetNewAiOffersQuery()
  const { userSessionId } = useAihubAnalyticsContext()

  // Check that first request to receive offers data is completed, after that we can start polling new ones
  const { isFetching: isFetchingBaseOffersData, isUninitialized } = useGetAiOffersQuery(
    { sessionId: userSessionId ?? '' },
    {
      skip: !userSessionId
    }
  )

  // Polling data when FCM is not allowed by the browser
  const { data: newAiOffersPollingData } = useGetNewAiOffersQuery(
    {
      sessionId: userSessionId ?? ''
    },
    {
      skip:
        (permission === 'granted' && !!token) ||
        !userSessionId ||
        isFetchingBaseOffersData ||
        isUninitialized,
      pollingInterval: 10000
    }
  )

  const showToast = useCallback(
    (title: string, desc?: string) => {
      notify(
        ({ closeToast }) => (
          <HStack gap={4}>
            <IoPricetagOutline size={20} />
            <VStack alignItems="flex-start">
              <Box fontWeight="bold" color="primary">
                {title}
              </Box>
              <Box>{desc}</Box>
            </VStack>
            <Button
              size="sm"
              variant="outline"
              borderRadius="lg"
              onClick={() => {
                openAihub()
                setActiveTabId('offers')
                closeToast()
              }}>
              Open
            </Button>
          </HStack>
        ),
        {
          closeOnClick: false
        }
      )
    },
    [openAihub, setActiveTabId]
  )

  const onNewNotificationMessage = useCallback(
    (message: NotificationMessage) => {
      if (!userSessionId) {
        return
      }

      getNewAiOffers({
        sessionId: userSessionId ?? ''
      })
        .unwrap()
        .then((newAiOffers) => {
          if (!newAiOffers.length) {
            return
          }

          const title = (newAiOffers.length === 1 && newAiOffers[0].name) || message.title
          const body = (newAiOffers.length === 1 && newAiOffers[0].description) || message.body

          showToast(title, body)
        })
        .catch(() => {
          /* empty */
        })
    },
    [getNewAiOffers, showToast, userSessionId]
  )

  // Show toasts from Firebase messaging is allowed
  useEffect(() => {
    return onMessage((message) => {
      onNewNotificationMessage(message)
    })
  }, [onMessage, onNewNotificationMessage])

  const onNewOfferFromPollingRef = useRefUpdated((title: string, body?: string) => {
    showToast(title, body)
  })

  // Show toasts when Firebase messaging is not allowed
  useEffect(() => {
    if (!newAiOffersPollingData?.length) {
      return
    }

    let title = ''
    let body = ''

    if (newAiOffersPollingData.length === 1) {
      title = newAiOffersPollingData[0].name ?? 'New deal'
      body = newAiOffersPollingData[0].description
    } else {
      title = `You got ${newAiOffersPollingData.length} special deals`
    }

    onNewOfferFromPollingRef.current(title, body)
  }, [newAiOffersPollingData, onNewOfferFromPollingRef])

  return {
    newAiOffers: token ? newAiOffersData : newAiOffersPollingData
  }
}
