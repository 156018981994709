export const getMessagesElement = () => document.getElementById('assistant-messages')

/**
 * Scrolls a container element to its bottom.
 *
 * This function is typically used to ensure that a chat or message container
 * is scrolled to display the latest content at the bottom. If the target element
 * cannot be found, the function exits gracefully.
 *
 * @remarks
 * - Sets the `scrollTop` property of the element to its `scrollHeight`, effectively scrolling it to the bottom.
 *
 * @example
 * ```typescript
 * handleScrollBottom(); // Ensures the chat messages are scrolled to the bottom.
 * ```
 */
export const handleScrollBottom = () => {
  const chatMessages = getMessagesElement()

  if (!chatMessages) return

  chatMessages.scrollTop = chatMessages.scrollHeight
}

/**
 * Waits for a DOM element to appear in the document and executes a callback function when it does.
 *
 * This function first checks if the element matching the given CSS selector already exists.
 * If found, the callback is executed immediately. Otherwise, it sets up a `MutationObserver`
 * to monitor the DOM for changes and executes the callback when the element is added.
 *
 * @param selector - A CSS selector string used to identify the target element.
 * @param callback - A function that is called when the element is found.
 *                   Receives the found `HTMLElement` as its parameter.
 *
 * @example
 * ```typescript
 * waitForElement('.dynamic-button', (button) => {
 *   button.addEventListener('click', () => {
 *     console.log('Button clicked!');
 *   });
 * });
 * ```
 */
export const waitForElement = (selector: string, callback: (element: HTMLElement) => void) => {
  const element = document.querySelector<HTMLElement>(selector)

  if (element) {
    callback(element)
    return
  }

  const observer = new MutationObserver((_mutations, obs) => {
    const e = document.querySelector<HTMLElement>(selector)
    if (e) {
      callback(e)
      obs.disconnect()
    }
  })

  observer.observe(document.body, {
    childList: true,
    subtree: true
  })
}
