import { Box, Center, HStack, Icon, Text, VStack } from '@chakra-ui/react'
import GrandText from 'ui/common/GrandText'
import { PiSpeakerSimpleHighDuotone } from 'react-icons/pi'
import { useEffect, useRef } from 'react'
import { useOnScreen } from 'ui/hooks'
import { LottiePlayer } from 'ui'
import { Avatar } from '../Avatar'
import { BG_GRADIENT } from '../constants'
import { ConversationalModeStatus } from '../../../../../assistant'
import useSpeechAnimationSrc from '../useSpeechAnimationSrc'
import { useAssistantContext } from '../asssistantContext'
import { type ChatMessageProps } from './types'

type ChatMessageExternalProps = Omit<ChatMessageProps, 'error'>

export const ChatMessageExternal = ({
  msg,
  renderProductsComponent,
  onSpeak,
  isLoadingSpeech,
  isPlayingSpeech,
  ...props
}: ChatMessageExternalProps) => {
  const itemRef = useRef(null)
  const isInView = useOnScreen(itemRef)
  const { conversationalMode, readMessage } = useAssistantContext()
  const utcDate = new Date(msg.created)
  const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000)
  const formattedMsgCreation = localDate.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  })

  const isConversationalModeOff =
    conversationalMode.status === ConversationalModeStatus.Inactive ||
    conversationalMode.status === ConversationalModeStatus.NotInitialized

  const animationSrc = useSpeechAnimationSrc({
    isLoading: isLoadingSpeech,
    isPlayingThumb: isPlayingSpeech
  })
  const renderSpeechIcon = () => {
    if (animationSrc) {
      return (
        <Box boxSize={6}>
          <LottiePlayer
            autoplay
            loop
            src={animationSrc}
            style={{ height: '100%', width: '100%' }}
          />
        </Box>
      )
    }

    return (
      <Icon
        className="ai-widget__message-speak"
        cursor={isConversationalModeOff ? 'pointer' : 'default'}
        flexShrink={1}
        as={PiSpeakerSimpleHighDuotone}
        boxSize={6}
        color={isConversationalModeOff ? 'gray' : 'gray.200'}
        onClick={() => {
          if (isConversationalModeOff) {
            void onSpeak(msg)
          }
        }}
      />
    )
  }

  useEffect(() => {
    if (isInView && !msg.isRead) {
      readMessage({ messageId: msg.id })
    }
  }, [isInView, msg.id, msg.isRead, readMessage])

  return (
    <HStack
      ref={itemRef}
      alignItems="flex-start"
      alignSelf="start"
      className="ai-widget__message"
      data-message-id={msg.id}
      gap={1}
      maxWidth="100%"
      width="full"
      {...props}>
      <Center borderRadius="50%" position="sticky" top={0}>
        <Avatar w="20px" h="20px" />
      </Center>

      <VStack alignItems="flex-start" flex={1} spacing={0}>
        <Box position="relative" w="full">
          <Box
            position="relative"
            borderRadius={16}
            borderTopLeftRadius={2}
            bg={BG_GRADIENT}
            p="2px"
            shadow="md">
            <Box
              bg="background.default"
              borderRadius={16}
              borderTopLeftRadius={2}
              py={3}
              px={4}
              position="relative"
              sx={{
                zIndex: 1
              }}>
              <GrandText
                bg="transparent"
                fontSize="sm"
                fontWeight="normal"
                w="auto"
                cursor="default"
                whiteSpace="break-spaces">
                {msg.text}
              </GrandText>
            </Box>
          </Box>
        </Box>

        <Text px={2} pt={1} w="full" textAlign="right" fontSize="xs" color="gray.400">
          {formattedMsgCreation}
        </Text>
        {renderProductsComponent(msg.products)}
      </VStack>

      {renderSpeechIcon()}
    </HStack>
  )
}
