import {
  Popover,
  PopoverArrow,
  PopoverBody,
  type PopoverBodyProps,
  PopoverCloseButton,
  PopoverContent,
  type PopoverContentProps,
  type PopoverProps,
  PopoverTrigger
} from '@chakra-ui/react'
import { cloneElement, forwardRef, type PropsWithChildren, type ReactElement } from 'react'
import { motion } from 'framer-motion'

// @ts-expect-error -- some type error
const MotionPopoverContent = motion.create(PopoverContent)

const animationVariants = {
  hidden: { opacity: 0, scale: 0.5, y: 50 },
  visible: {
    opacity: 1,
    scale: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 500,
      damping: 20,
      mass: 1,
      duration: 0.5
    }
  }
}

type AssistantTeaserProps = Omit<PopoverProps, 'children'> &
  PropsWithChildren<{
    target: ReactElement
    contentProps?: PopoverContentProps
    bodyProps?: PopoverBodyProps
    arrowProps?: PopoverBodyProps
  }>

export const AssistantTeaser = forwardRef<HTMLElement, AssistantTeaserProps>(
  (
    {
      target,
      contentProps,
      arrowProps,
      bodyProps,
      children,
      isOpen,
      ...props
    }: AssistantTeaserProps,
    ref
  ) => {
    return (
      <Popover closeOnBlur={false} placement="auto-end" isOpen={isOpen} {...props}>
        <PopoverTrigger>{cloneElement(target, { ref })}</PopoverTrigger>

        <MotionPopoverContent
          width="auto"
          maxW="30rem"
          initial="hidden"
          animate={isOpen ? 'visible' : 'hidden'}
          variants={animationVariants}
          _focusVisible={{
            outline: 'none'
          }}
          {...contentProps}>
          <PopoverArrow {...arrowProps} />
          <PopoverCloseButton _focus={{ outline: 'none' }} aria-label="close" />
          <PopoverBody pr={10} borderRadius="md" {...bodyProps}>
            {children}
          </PopoverBody>
        </MotionPopoverContent>
      </Popover>
    )
  }
)

AssistantTeaser.displayName = 'AssistantTeaser'
