import {
  type BaseEvent,
  type ConsentModeEvent,
  type ConsentState,
  type DataCollectionConfig,
  type GoogleConsentSettings,
  type GTMEvent,
  type IDataLayerManager
} from '../typings'
import { type ConsentManager } from './consent-manager'

export class DataLayerManager implements IDataLayerManager {
  private config: DataCollectionConfig
  private readonly consentManager: ConsentManager
  private dataLayer: GTMEvent[] = []

  constructor(config: DataCollectionConfig, consentManager: ConsentManager) {
    this.config = config
    this.consentManager = consentManager
    this.initialize()
  }

  private initialize(): void {
    this.injectGTMScript()
    this.processDataLayerQueue()
    window.dataLayer = window.dataLayer || []

    this.updateGoogleConsent({
      ad_storage: 'denied',
      analytics_storage: 'denied',
      functionality_storage: 'denied',
      personalization_storage: 'denied',
      ad_personalization: 'denied',
      ad_user_data: 'denied',
      security_storage: 'granted'
    })

    this.consentManager.onConsentUpdate((state) => {
      this.handleConsentUpdate(state)
    })

    if (this.config.isDebugMode) {
      // eslint-disable-next-line no-console -- We want to log
      console.log('Initialized DataLayerManager')
    }
  }

  private injectGTMScript(): void {
    if (document.readyState === 'complete') {
      this.injectScripts()
    } else {
      window.addEventListener('load', () => this.injectScripts())
    }
  }

  private injectScripts(): void {
    if (!this.config.gtmConfig.centralContainerId) {
      throw new Error('GTM container ID is required')
    }

    if (typeof document === 'undefined' || !document.head) {
      return
    }

    // Add explicit checks for existing scripts
    const gtmScriptId = `gtm-script-${this.config.gtmConfig.centralContainerId}`
    const gtmInlineId = `gtm-inline-${this.config.gtmConfig.centralContainerId}`

    if (document.getElementById(gtmScriptId) || document.getElementById(gtmInlineId)) {
      if (this.config.isDebugMode) {
        // eslint-disable-next-line no-console -- Expected in debug mode
        console.log('GTM scripts already exist')
      }
      return
    }

    // Create and configure the GTM script
    const script = document.createElement('script')
    script.id = gtmScriptId
    script.async = true
    script.src = `https://www.googletagmanager.com/gtm.js?id=${this.config.gtmConfig.centralContainerId}`

    script.onerror = () => {
      if (this.config.isDebugMode) {
        // eslint-disable-next-line no-console -- Expected in debug mode
        console.error('Failed to load GTM script')
      }
    }

    const inlineScript = document.createElement('script')
    inlineScript.id = gtmInlineId
    inlineScript.textContent = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${this.config.gtmConfig.centralContainerId}');
  `

    document.head.appendChild(inlineScript)
    document.head.appendChild(script)

    if (document.body) {
      const noscriptId = `gtm-noscript-${this.config.gtmConfig.centralContainerId}`
      if (!document.getElementById(noscriptId)) {
        const noscript = document.createElement('noscript')
        noscript.id = noscriptId
        noscript.innerHTML = `
        <iframe src='https://www.googletagmanager.com/ns.html?id=${this.config.gtmConfig.centralContainerId}'
          height='0' width='0' style='display:none;visibility:hidden'></iframe>
      `
        document.body.insertBefore(noscript, document.body.firstChild)
      }
    }
  }

  private readonly handleConsentUpdate = (state: ConsentState): void => {
    const googleConsent: GoogleConsentSettings = {
      ad_storage: state.marketing ? 'granted' : 'denied',
      analytics_storage: state.analytics ? 'granted' : 'denied',
      functionality_storage: state.preferences ? 'granted' : 'denied',
      personalization_storage: state.marketing ? 'granted' : 'denied',
      ad_personalization: state.marketing ? 'granted' : 'denied',
      ad_user_data: state.marketing ? 'granted' : 'denied',
      security_storage: 'granted'
    }

    this.updateGoogleConsent(googleConsent)
  }

  private updateGoogleConsent(settings: GoogleConsentSettings): void {
    try {
      const consentEvent: ConsentModeEvent = {
        event: 'consent_update',
        consent: settings
      }

      if (this.config.isDebugMode) {
        // eslint-disable-next-line -- Logs expected in debug mode
        console.log('Updating Google consent:', consentEvent)
      }

      window.dataLayer.push(consentEvent)
      window.gtag?.('consent', 'update', settings)
      window.gtag?.('set', 'ads_data_redaction', settings.ad_storage === 'denied')
    } catch (error) {
      if (this.config.isDebugMode) {
        // eslint-disable-next-line -- Logs expected in debug mode
        console.error('Failed to update Google consent:', error)
      }
    }
  }

  public pushEvent<T extends BaseEvent>(name: T['event'], params: Omit<T, 'event'>): void {
    try {
      const event: GTMEvent = {
        event: name,
        store_id: this.config.storeId,
        client_timestamp: Date.now(),
        consent_state: this.consentManager.getCurrentConsentState(),
        destinations: this.config.destinations,
        ...params
      }

      if (this.config.isDebugMode) {
        // eslint-disable-next-line -- Logs expected in debug mode
        console.log('Pushing event to dataLayer:', event)
      }

      this.dataLayer.push(event)
      window.dataLayer?.push(event)
    } catch (error) {
      if (this.config.isDebugMode) {
        // eslint-disable-next-line no-console -- We want to log
        console.error('Failed to push event:', error)
      }
    }
  }

  public destroy(): void {
    this.consentManager.removeConsentUpdateCallback(this.handleConsentUpdate)
    this.dataLayer = []

    if (this.config.isDebugMode) {
      // eslint-disable-next-line no-console -- We want to log
      console.log('Destroyed DataLayerManager')
    }
  }

  public getDataLayerHistory(): GTMEvent[] {
    return [...this.dataLayer]
  }

  private processDataLayerQueue(): void {
    const existingDataLayer = window.dataLayer ?? []
    window.dataLayer = existingDataLayer

    existingDataLayer.forEach((event) => {
      if (this.isGTMEvent(event)) {
        this.dataLayer.push(event)
      }
    })
  }

  private isGTMEvent(event: GTMEvent | ConsentModeEvent): event is GTMEvent {
    return 'store_id' in event && 'consent_state' in event
  }
}
