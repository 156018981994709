import { Center, Spinner, VStack } from '@chakra-ui/react'
import { useMemo, useRef } from 'react'
import useGetCachedRequest from 'api/utils/next/useGetCachedRequest'
import type { AssistantDTO } from 'ecosystem'
import { NoData } from 'ui'
import { useInView } from 'react-intersection-observer'
import { useSetReset } from '../../../../common/hooks'
import { ConversationalModeStatus } from '../../../../assistant'
import { useAihubAssistant } from './useAihubAssistant'
import ChatProducts from './ChatProducts'
import Chat from './Chat'
import { type AssistantContextState, type ConversationalMode } from './types'
import { AssistantProvider } from './asssistantContext'

interface AihubAssistantProps {
  paths: {
    chat: string
    settings: string
    thread: string
    textToSpeech: string
    products: string
    categories: string
    tags: string
    brands: string
  }
  components?: AssistantContextState['components']
}

export const AihubAssistant = (props: AihubAssistantProps) => {
  const { paths, components } = props
  const { data: settings, isLoading } = useGetCachedRequest<AssistantDTO>(paths.settings)
  const inputRef = useRef<HTMLInputElement>(null)
  const { ref: wrapperRef, inView: isInView } = useInView({
    threshold: 0.2
  })

  const {
    inputValue,
    setInputValue,
    isSendingMessage,
    sendMessage,
    readMessage,
    history,
    newMessages,
    sendInitRequest,
    initError,
    errors,
    isInit,
    textToSpeech,
    isLoadingTextToSpeech,
    isPlayingTextToSpeech,
    onSpeakAudioRef,
    textToSpeechError
  } = useAihubAssistant({
    paths
  })

  const [conversationalMode, dispatchConversationalMode] = useSetReset<ConversationalMode>({
    status: ConversationalModeStatus.NotInitialized
  })

  const assistantProviderValue: AssistantContextState = useMemo(() => {
    return {
      readMessage,
      isInView,
      onSpeakAudioRef,
      conversationalMode,
      dispatchConversationalMode,
      inputValue,
      setInputValue,
      isLoading: isSendingMessage,
      handleSend: sendMessage,
      history,
      newMessages,
      inputRef,
      initError,
      reinit: sendInitRequest,
      errors,
      isInit,
      settings,
      components,
      paths
    }
  }, [
    readMessage,
    isInView,
    onSpeakAudioRef,
    conversationalMode,
    dispatchConversationalMode,
    inputValue,
    setInputValue,
    isSendingMessage,
    sendMessage,
    history,
    newMessages,
    inputRef,
    initError,
    sendInitRequest,
    errors,
    isInit,
    settings,
    components,
    paths
  ])

  if (isLoading) {
    return (
      <Center flex={1}>
        <Spinner size="xl" />
      </Center>
    )
  }

  // todo: figure out what to do when there no settings
  if (!settings) {
    return <NoData>No assistant settings</NoData>
  }

  return (
    <AssistantProvider value={assistantProviderValue}>
      <VStack position="relative" gap="1" flex={1} w="full" alignItems="stretch" ref={wrapperRef}>
        <Chat
          isConversationalModeEnabled
          isPlayingSpeech={isPlayingTextToSpeech}
          isLoadingSpeech={isLoadingTextToSpeech}
          onSpeak={textToSpeech}
          onSpeakError={textToSpeechError}
          renderProductsComponent={(products) => <ChatProducts products={products} />}
        />
      </VStack>
    </AssistantProvider>
  )
}
