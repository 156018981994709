import { useCallback, useEffect, useRef, useState } from 'react'
import useGenericRequest from 'api/utils/next/useGenericRequest'
import { v4 as uuidv4 } from 'uuid'
import type {
  AssistantMessageFE,
  AssistantV2ChatRequestDTO,
  AssistantV2ChatResponseDTO,
  AssistantV2ThreadResponseDTO
} from 'ecosystem'
import { useDispatch, useSelector } from 'shared-redux'
import {
  addError,
  addMessage,
  resetChat,
  selectAssistantErrors,
  selectAssistantHistory,
  selectAssistantInitError,
  selectAssistantIsInit,
  selectAssistantNewMessages,
  selectAssistantSessionUserId,
  selectAssistantThreadId,
  setInitError,
  setIsInit,
  setIsSendingMessage,
  setSessionUserId,
  setThreadId
} from 'shared-redux/state'
import { assistantStorage, isNullable } from 'shared-utils'
import { useRouterChanged } from 'ui/hooks'
import { useUser } from 'auth'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { overrideText } from 'ui/lib/overrides'
import { useAssistantSize } from '../useAssistantSize'
import { useTextToSpeech } from '../../hooks/useTextToSpeech'
import { type AssistantTextOverrides } from '../../types'

interface HookProps {
  paths: {
    chat: string
    thread: string
    textToSpeech: string
  }
}

export const useAssistantV2 = (props: HookProps) => {
  const overrides = useOverridesContext<keyof AssistantTextOverrides>()
  const dispatch = useDispatch()
  const { paths } = props

  const isInit = useSelector(selectAssistantIsInit)
  const initError = useSelector(selectAssistantInitError)
  const errors = useSelector(selectAssistantErrors)
  const threadId = useSelector(selectAssistantThreadId)
  const sessionUserId = useSelector(selectAssistantSessionUserId)
  const history = useSelector(selectAssistantHistory)
  const newMessages = useSelector(selectAssistantNewMessages)

  const { isExpanded, toggleSize, shrinkSize, expandSize, isOpen } = useAssistantSize()

  const { user, isLoading: isLoadingUser, isValidating: isValidatingUser } = useUser()

  const [inputValue, setInputValue] = useState('')
  const { fetchNextRoute: sendMessageRequest, isLoading: isSendingMessage } =
    useGenericRequest<AssistantV2ChatResponseDTO>()
  const { fetchNextRoute: getThreadId } = useGenericRequest<AssistantV2ThreadResponseDTO>()

  const _sendMessage = useCallback(
    async (text: string, cbBefore?: (text: string) => Promise<void>) => {
      if (!threadId) {
        return
      }

      let attempt = 0

      await cbBefore?.(text)
      dispatch(setIsSendingMessage(true))

      const repeatSendMessage = async (): Promise<AssistantV2ChatResponseDTO> => {
        attempt += 1

        const res = await sendMessageRequest(paths.chat, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json'
          },
          body: JSON.stringify({
            question: text,
            thread_id: threadId
          } as AssistantV2ChatRequestDTO)
        }).unwrap()

        if (!res.response) {
          if (attempt <= 1) {
            return repeatSendMessage()
          }

          throw new Error('RESPONSE_IS_EMPTY')
        }
        return res
      }

      try {
        const res = await repeatSendMessage()
        dispatch(
          addMessage({
            id: uuidv4(),
            text: res.response,
            products: res.products,
            role: 'assistant',
            created: res.created
          })
        )
      } catch (error) {
        throw new Error(`${error}`)
      } finally {
        dispatch(setIsSendingMessage(false))
      }
    },
    [dispatch, paths.chat, sendMessageRequest, threadId]
  )

  const sendMessage = useCallback(
    async (text: string) => {
      const id = uuidv4()

      try {
        await _sendMessage(text, async () => {
          return new Promise((resolve) => {
            const newMessage: AssistantMessageFE = {
              id,
              role: 'client',
              text,
              created: new Date().toISOString()
            }

            dispatch(addMessage(newMessage))
            resolve()
          })
        })
      } catch {
        dispatch(
          addError({
            id,
            error: overrideText('Ej levererad.', overrides?.assistantErrorUndelivered)
          })
        )
      }
    },
    [_sendMessage, dispatch, overrides?.assistantErrorUndelivered]
  )

  const sendInitRequest = useCallback(async () => {
    try {
      await _sendMessage(user?.customer ? `Hi I'm ${user.customer.firstName}` : '', async () => {
        return new Promise((resolve) => {
          dispatch(setIsInit(false))
          dispatch(setInitError(null))
          resolve()
        })
      })

      dispatch(setIsInit(true))
      dispatch(setInitError(null))
    } catch {
      dispatch(
        setInitError(
          overrideText('Tjänsten är inte tillgänglig.', overrides?.assistantErrorServiceUnavailable)
        )
      )
    }
  }, [_sendMessage, dispatch, user?.customer, overrides?.assistantErrorServiceUnavailable])

  const {
    textToSpeech,
    isLoading: isLoadingTextToSpeech,
    isPlaying: isPlayingTextToSpeech,
    error: textToSpeechError,
    audioRef: onSpeakAudioRef
  } = useTextToSpeech(paths.textToSpeech)

  const initAttemptedRef = useRef(false)

  useEffect(() => {
    if (isLoadingUser || isValidatingUser) {
      return
    }

    if (
      isNullable(user?.customerId) !== isNullable(sessionUserId) ||
      user?.customerId !== sessionUserId
    ) {
      if (!user?.customerId) {
        assistantStorage.removeSessionUserId()
        dispatch(setSessionUserId(undefined))
      } else {
        assistantStorage.setSessionUserId(user.customerId)
        dispatch(setSessionUserId(user.customerId))
      }

      assistantStorage.removeHistory()
      assistantStorage.removeThreadId()
      dispatch(resetChat())

      return
    }

    if (threadId) {
      assistantStorage.setThreadId(threadId)
      return
    }

    const abortController = new AbortController()

    getThreadId(`${paths.thread}${user?.customerId ? `?customerId=${user?.customerId}` : ''}`, {
      signal: abortController.signal
    })
      .unwrap()
      .then(({ thread }) => {
        assistantStorage.setThreadId(thread)
        dispatch(setThreadId(thread))
      })
      .catch(() => void 0)

    return () => {
      abortController.abort()
    }
  }, [
    dispatch,
    getThreadId,
    isLoadingUser,
    isValidatingUser,
    paths.thread,
    threadId,
    sessionUserId,
    user?.customerId
  ])

  useEffect(() => {
    if (
      !threadId ||
      isInit ||
      isLoadingUser ||
      isValidatingUser ||
      history ||
      initAttemptedRef.current
    ) {
      return
    }

    initAttemptedRef.current = true
    void sendInitRequest()
  }, [dispatch, history, isInit, isLoadingUser, isValidatingUser, sendInitRequest, threadId])

  useEffect(() => {
    if (!isInit || !history) {
      return
    }

    const filtered = history.filter((message) => !errors[message.id])

    assistantStorage.setHistory(filtered.length ? filtered : null)
  }, [errors, history, isInit])

  const handleRouterChange = useCallback(() => {
    if (isOpen) {
      shrinkSize()
    }
  }, [isOpen, shrinkSize])

  useRouterChanged(({ meta: { isPathChanged } }) => {
    if (isPathChanged) {
      handleRouterChange()
    }
  })

  return {
    sendMessage,
    isSendingMessage,
    setInputValue,
    inputValue,
    isInit,
    history,
    newMessages,
    sendInitRequest,
    initError,
    errors,
    isExpanded,
    toggleSize,
    expandSize,
    shrinkSize,
    textToSpeech,
    isLoadingTextToSpeech,
    isPlayingTextToSpeech,
    textToSpeechError,
    onSpeakAudioRef
  }
}
