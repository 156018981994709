import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react'
import GrandTooltip from 'ui/common/GrandTooltip'
import { IoMdAlert } from 'react-icons/io'
import GrandText from 'ui/common/GrandText'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import type { AssistantTextOverrides } from '../types'
import type { ChatMessageProps } from './types'

type ChatMessageClientProps = Pick<ChatMessageProps, 'error' | 'msg'>

export const ChatMessageClient = ({ msg, error, ...props }: ChatMessageClientProps) => {
  const overrides = useOverridesContext<keyof AssistantTextOverrides>()

  return (
    <HStack alignSelf="end" maxWidth="calc(90% - 45px)" {...props}>
      {!!error && (
        <GrandTooltip color="red" label={error}>
          <Box color="red">
            <IoMdAlert />
          </Box>
        </GrandTooltip>
      )}

      <VStack alignItems="flex-start" flex={1} gap={0}>
        <Flex
          bg="background.surface"
          borderStyle="solid"
          borderColor="white"
          borderWidth={2}
          borderRadius={16}
          borderBottomRightRadius={2}
          px={4}
          py={3}
          shadow="md">
          <GrandText fontSize="sm" fontWeight="normal" w="full">
            {msg.text}
          </GrandText>
        </Flex>
        {!error ? (
          <Text px={2} pt={1} w="full" textAlign="right" fontSize="xs" color="gray.400">
            {overrideText('Skickat', overrides?.assistantStatusSent)}
          </Text>
        ) : null}
      </VStack>
    </HStack>
  )
}
