import { useGetAiOffersQuery } from 'shared-redux/state'
import { useCallback, useMemo } from 'react'
import { useDispatch } from 'shared-redux'
import { baseSplitApi } from 'shared-redux/state/store'
import type { AiOfferDTO } from 'ecosystem'
import { arrayFindAndDeleteItem } from 'shared-utils'
import { useAihubAnalyticsContext } from '../../aihubAnalytics'

export const useAiOffers = () => {
  const dispatch = useDispatch()
  const { userSessionId } = useAihubAnalyticsContext()
  const { data: offers, isLoading } = useGetAiOffersQuery(
    {
      sessionId: userSessionId ?? ''
    },
    { skip: !userSessionId }
  )

  const unreadOffersCount = useMemo(
    () => (offers ?? [])?.filter((offer) => !offer.opened).length,
    [offers]
  )

  const hideAiOffer = useCallback(
    (offer: AiOfferDTO) => {
      dispatch(
        // @ts-expect-error -- name of the query
        baseSplitApi.util.updateQueryData('getAiOffers', undefined, (draft: AiOfferDTO[]) => {
          if (Array.isArray(draft)) {
            arrayFindAndDeleteItem({ arr: draft, compare: (item) => item.id === offer.id })
          }
        })
      )
    },
    [dispatch]
  )

  return {
    offers,
    isLoading,
    unreadOffersCount,
    hideAiOffer
  }
}
