import { useEffect } from 'react'
import { useRouterChanged } from 'ui/hooks'
import { type AihubTabId } from 'ecosystem'
import { createUrl } from 'shared-utils'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { useAihubState } from './useAihubState'
import { useAihubNotifications } from './useAihubNotifications'

export const useAihub = () => {
  const { isOpen, closeAihub, openAihub, setActiveTabId } = useAihubState()
  const searchParams = useSearchParams()
  const router = useRouter()
  const pathname = usePathname()

  useRouterChanged(({ meta: { isPathChanged } }) => {
    if (isPathChanged && isOpen) {
      closeAihub()
    }
  })

  useEffect(() => {
    if (!searchParams || !pathname) {
      return
    }

    const aihubOpen = searchParams.get('aihubOpen')
    const activeTab = searchParams.get('aihubActiveTab')

    if (!aihubOpen && !activeTab) {
      return
    }

    if (aihubOpen === 'true') {
      openAihub()
    }

    if (activeTab) {
      setActiveTabId(activeTab as AihubTabId)
    }

    const newSearchParams = new URLSearchParams(searchParams)

    newSearchParams.delete('aihubOpen')
    newSearchParams.delete('aihubActiveTab')

    router.replace(createUrl(pathname, newSearchParams))
  }, [openAihub, pathname, router, searchParams, setActiveTabId])

  const { newAiOffers } = useAihubNotifications()

  return {
    newAiOffers
  }
}
