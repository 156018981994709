import { Badge, Box, HStack } from '@chakra-ui/react'
import { UICountdown } from 'ui/common/UICountdown'
import { type AiOfferDTO } from 'ecosystem'
import { useAiOffersContext } from '../../aihubOffersContext'

interface OfferHeaderProps {
  offer: AiOfferDTO
}

export const OfferHeader = ({ offer }: OfferHeaderProps) => {
  const { hideAiOffer } = useAiOffersContext()

  return (
    <HStack justifyContent="space-between">
      <Box fontSize="md" fontWeight="semibold" color="primary">
        {offer.name}
      </Box>

      {!!offer.expiresAt && (
        <Badge
          variant="solid"
          colorScheme="red"
          borderRadius="xl"
          textTransform="none"
          px={2}
          whiteSpace="nowrap"
          fontSize="xs">
          Expires in{' '}
          <UICountdown date={new Date(offer.expiresAt)} onComplete={() => hideAiOffer(offer)} />
        </Badge>
      )}
    </HStack>
  )
}
