import type { NextApiRequest, NextApiResponse } from 'next'
import {
  type AiOfferAcceptRequestDTO,
  type AiOfferAcceptResponseDTO,
  type AiOfferAnalyticsRequestDTO,
  type AiOfferAnalyticsResponseDTO,
  type AiOfferListRequestDTO,
  type AiOfferListResponseDTO,
  type AiOfferReadRequestDTO,
  type AiOfferReadResponseDTO,
  type ErrorRes,
  type Language
} from 'ecosystem'
import { useLocaleCredentials as setLocaleCredentials } from 'api'
import storefrontApi from 'api/storefront-api'

export const getAiOffersListHandler = async (
  req: NextApiRequest,
  res: NextApiResponse<AiOfferListResponseDTO | ErrorRes>
) => {
  const locale = (req.cookies.NEXT_LOCALE as Language) || 'sv'
  setLocaleCredentials(locale)

  if (req.method !== 'POST') {
    res.status(405).json({ error: 'Method not supported.' })
    return
  }

  const body = req.body as AiOfferListRequestDTO

  let aiOfferListResponse: AiOfferListResponseDTO | null

  try {
    aiOfferListResponse = await storefrontApi.aiOffer.list(body)
  } catch (error: unknown) {
    res.status(502).json({ error: (error as Error)?.message })
    return
  }

  if (!aiOfferListResponse) {
    res.status(502).json({ error: 'Response is unavailable' })
    return
  }

  res.status(200).json(aiOfferListResponse)
}

export const readAiOfferHandler = async (
  req: NextApiRequest,
  res: NextApiResponse<AiOfferReadResponseDTO | ErrorRes | null>
) => {
  const locale = (req.cookies.NEXT_LOCALE as Language) || 'sv'
  setLocaleCredentials(locale)

  if (req.method !== 'POST') {
    res.status(405).json({ error: 'Method not supported.' })
    return
  }

  const { offerId } = req.body as AiOfferReadRequestDTO

  if (!offerId) {
    res.status(404).json({ error: 'Not found' })
    return
  }

  try {
    const aiOfferResponse = await storefrontApi.aiOffer.readOffer({
      offerId
    })

    res.status(200).json(aiOfferResponse)
  } catch (error: unknown) {
    res.status(502).json({ error: (error as Error)?.message })
  }
}

export const acceptAiOfferHandler = async (
  req: NextApiRequest,
  res: NextApiResponse<AiOfferAcceptResponseDTO | ErrorRes | null>
) => {
  const locale = (req.cookies.NEXT_LOCALE as Language) || 'sv'
  setLocaleCredentials(locale)

  if (req.method !== 'POST') {
    res.status(405).json({ error: 'Method not supported.' })
    return
  }

  const { offerId } = req.body as AiOfferAcceptRequestDTO

  if (!offerId) {
    res.status(404).json({ error: 'Not found' })
    return
  }

  try {
    const aiOfferResponse = await storefrontApi.aiOffer.acceptOffer({
      offerId
    })

    res.status(200).json(aiOfferResponse)
  } catch (error: unknown) {
    res.status(502).json({ error: (error as Error)?.message })
  }
}

export const sendAnalyticsAiOfferHandler = async (
  req: NextApiRequest,
  res: NextApiResponse<AiOfferAnalyticsResponseDTO | ErrorRes | null>
) => {
  const locale = (req.cookies.NEXT_LOCALE as Language) || 'sv'
  setLocaleCredentials(locale)

  if (req.method !== 'POST') {
    res.status(405).json({ error: 'Method not supported.' })
    return
  }

  const { token, userSessionId, event, data } = req.body as AiOfferAnalyticsRequestDTO

  if (!userSessionId) {
    res.status(400).json({ error: 'UserSessionId is not provided' })
    return
  }

  try {
    const aiOfferResponse = await storefrontApi.aiOffer.sendAnalytics({
      token,
      userSessionId,
      event,
      data
    })

    res.status(200).json(aiOfferResponse)
  } catch (error: unknown) {
    res.status(502).json({ error: (error as Error)?.message })
  }
}
