'use client'

import { usePathname } from 'next/navigation'
import { useEffect, useRef } from 'react'
import { useGTMEvents } from './useGtmEvents'

export const useGTMPageViewTracker = () => {
  const { trackPageView, isInitialized, dataLayerManager, consentManager, config } = useGTMEvents()
  const pathname = usePathname()
  const isFirstPageView = useRef(true)
  const hasConsent = useRef(false)

  useEffect(() => {
    if (!pathname || !isInitialized || !dataLayerManager || !consentManager) {
      return
    }

    const trackPage = () => {
      const title = document.title
      try {
        trackPageView(pathname, title, { level: 'standard', requiresConsent: ['analytics'] })
        if (config?.isDebugMode) {
          // eslint-disable-next-line no-console -- Expected debug log
          console.log('Tracked page event: ', pathname, title)
        }
      } catch (e) {
        if (config?.isDebugMode) {
          const errorMessage = e instanceof Error ? e.message : 'Unknown error'
          // eslint-disable-next-line no-console -- Expected debug log
          console.error('Error tracking page view: ', errorMessage)
        }
      }
    }

    const handleConsentUpdate = () => {
      if (!isFirstPageView.current) {
        return
      }
      isFirstPageView.current = false
      hasConsent.current = true
      trackPage()
    }

    if (!isFirstPageView.current && hasConsent.current) {
      trackPage()
      return
    }

    consentManager.onConsentUpdate(handleConsentUpdate)

    return () => {
      consentManager.removeConsentUpdateCallback(handleConsentUpdate)
    }
  }, [
    config?.isDebugMode,
    consentManager,
    dataLayerManager,
    isInitialized,
    pathname,
    trackPageView
  ])
}
