import { Box, type BoxProps } from '@chakra-ui/react'
import { MotionChakraBox } from 'ui'
import { type CartItem, type CartState } from 'ecosystem'
import { UseSearchParamsSuspense } from 'ui/wrappers'
import { AihubProvider } from '../aihubContext'
import type { AihubContextState } from '../types'
import { useAihub } from './useAihub'
import { AihubMain } from './AihubMain'
import { useAihubState } from './useAihubState'
import { AihubTrigger } from './AihubTrigger'

interface AIHubProps {
  handleAddToCart: (cartItem: CartItem) => void
  cartState: CartState
  wrapperProps?: BoxProps
  assistant?: AihubContextState['assistant']
  offers?: AihubContextState['offers']
}

const AihubComponent = ({
  wrapperProps,
  assistant,
  offers,
  handleAddToCart,
  cartState
}: AIHubProps) => {
  const { newAiOffers } = useAihub()

  const { isOpen, isExpanded } = useAihubState()

  return (
    <AihubProvider
      value={{
        assistant,
        offers,
        handleAddToCart,
        cartState,
        newAiOffers
      }}>
      <Box
        className="AIHub"
        zIndex={3}
        position="fixed"
        left={0}
        bottom={0}
        top={{ base: '8vh', md: '14vh' }}
        transition="all 0.25s ease"
        w="100%"
        maxW={{
          base: '100%',
          sm: isExpanded ? '65rem' : '25rem'
        }}
        transform="translateX(-100%)"
        {...wrapperProps}>
        <AihubTrigger />

        <MotionChakraBox
          initial={{ translateX: '0%' }}
          animate={{ translateX: isOpen ? '100%' : 0 }}
          transition={{ type: 'spring', bounce: 0.4, duration: 0.4 }}
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          w="100%"
          h="100%"
          backgroundColor="background.default"
          boxShadow="md"
          borderEndRadius="3xl">
          <AihubMain />
        </MotionChakraBox>
      </Box>
    </AihubProvider>
  )
}

export const Aihub = (props: AIHubProps) => (
  <UseSearchParamsSuspense>
    <AihubComponent {...props} />
  </UseSearchParamsSuspense>
)
