import { Box, useRadio, type UseRadioProps } from '@chakra-ui/react'
import { type AiOfferProductDTO } from 'ecosystem'
import { OfferProduct } from '../OfferProduct'

interface PlaceSelectionItemProps {
  radioProps: UseRadioProps
  product: AiOfferProductDTO
}

export const ProductSelectionItem = ({ radioProps, product }: PlaceSelectionItemProps) => {
  const { getInputProps, getRadioProps } = useRadio(radioProps)

  const input = getInputProps()
  const checkbox = getRadioProps()

  return (
    <Box as="label" w="100%">
      <input {...input} />
      <OfferProduct
        {...checkbox}
        boxShadow="0 0 0 2px var(--chakra-colors-gray-200)"
        _checked={{
          borderColor: 'primary',
          boxShadow: '0 0 0 2px var(--chakra-colors-primary)'
        }}
        aria-hidden="false"
        cursor="pointer"
        product={product}
      />
    </Box>
  )
}
