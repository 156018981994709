'use client'

import { createContext, type ReactNode, useMemo } from 'react'
import { type StoreAnalytics, type StorePrivacy } from 'ecosystem'
import { isArray } from 'lodash'
import { type DataLayerManager } from '../managers/data-layer-manager'
import { type ConsentManager } from '../managers/consent-manager'
import { useGTMSetup } from '../hooks/useGtmSetup'
import { type DataCollectionConfig, type Destination } from '../typings'

interface GTMContextType {
  dataLayerManager: DataLayerManager | null
  consentManager: ConsentManager | null
  isInitialized: boolean
  error: Error | null
  config: DataCollectionConfig | null
}

export const GTMContext = createContext<GTMContextType>({
  dataLayerManager: null,
  consentManager: null,
  isInitialized: false,
  error: null,
  config: null
})

export function GTMProvider({
  children,
  strictMode = true,
  storeId,
  isDebugMode,
  analytics,
  privacy
}: {
  children: ReactNode
  strictMode?: boolean
  storeId: string
  isDebugMode?: boolean
  analytics?: StoreAnalytics
  privacy?: StorePrivacy
}) {
  if (!analytics?.gtmId || !isArray(analytics?.destinations)) {
    const errorText = 'GTMProvider: Incomplete Analytics configuration'

    if (strictMode) {
      throw new Error(errorText)
    }

    // eslint-disable-next-line no-console -- need for debug
    console.error(errorText)
  }

  const gtmConfig: DataCollectionConfig = useMemo(
    () => ({
      storeId,
      isDebugMode: isDebugMode ?? false,
      managementMode: privacy?.cookieBotId ? ('managed' as const) : ('self' as const),
      cookiebotConfig: {
        containerId: privacy?.cookieBotId
      },
      gtmConfig: {
        centralContainerId: analytics?.gtmId ?? ''
      },
      consentSettings: {
        defaultConsentState: {
          analytics: false,
          marketing: false,
          preferences: false
        }
      },
      destinations:
        analytics?.destinations.map((d) => ({
          ...d,
          type: d.type.toLowerCase() as Destination['type']
        })) ?? []
    }),
    [analytics?.destinations, analytics?.gtmId, privacy?.cookieBotId, isDebugMode, storeId]
  )

  const { dataLayerManager, consentManager, isInitialized, error, config } = useGTMSetup(gtmConfig)

  if (!isInitialized) {
    if (isDebugMode) {
      // eslint-disable-next-line no-console -- Debug mode
      console.log('GTMProvider: Not initialized')
    }
    return <>{children}</>
  }

  if (isDebugMode) {
    // eslint-disable-next-line no-console -- Debug mode
    console.log('GTMProvider: Initialized')
  }

  return (
    <GTMContext.Provider value={{ dataLayerManager, consentManager, isInitialized, error, config }}>
      {children}
    </GTMContext.Provider>
  )
}
