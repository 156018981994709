import { Box, HStack } from '@chakra-ui/react'
import { Price, type PriceOverrides } from 'ui'
import { useMemo } from 'react'
import { commaSeparator } from 'shared-utils'
import { overrideText } from 'ui/lib/overrides'
import { Currency } from 'ecosystem'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { useUIGlobalsContext } from 'ui/lib/ui-globals/hooks'

interface OfferTotalProps {
  total: number
  totalOriginal: number
}

export const OfferTotal = ({ total, totalOriginal }: OfferTotalProps) => {
  const overrides = useOverridesContext<keyof PriceOverrides>()
  const { priceMaximumFractionDigits } = useUIGlobalsContext()

  const discount = useMemo(() => {
    const discountSum = totalOriginal - total
    return {
      sum: discountSum,
      percentage: (discountSum / totalOriginal) * 100
    }
  }, [total, totalOriginal])

  return (
    <HStack alignItems="baseline" flexWrap="wrap" mt={4}>
      <HStack alignItems="baseline">
        <Box fontWeight="bold">Total:</Box>
        <Price
          priceProps={{
            fontSize: 'md'
          }}
          alignItems="baseline"
          price={total}
          originalPrice={totalOriginal}
          whiteSpace="nowrap"
        />
      </HStack>

      {discount.sum > 0 && (
        <Box as="span" fontSize="2xs" color="text.mild">
          {`you save `}
          {commaSeparator(discount.sum, priceMaximumFractionDigits)}{' '}
          {overrideText(Currency.SEK, overrides?.priceCurrency)}
          {` (${commaSeparator(discount.percentage, 0)}% discount)`}
        </Box>
      )}
    </HStack>
  )
}
