import { useIsClient } from 'ui/hooks'
import { useAiOffers } from '../modules/aihubOffersModule'
import { useAihubAssistantUnreadCount } from '../modules/aihubAssistantModule/useAihubAssistantUnreadCount'

export const useAihubUnreadCount = () => {
  const { unreadOffersCount } = useAiOffers()
  // it causes hydration issue because uses localhost value to initiate redux reducer value
  const { unreadMessagesCount: unreadAssistantCount } = useAihubAssistantUnreadCount()
  const isClient = useIsClient()

  return {
    unreadOffersCount: isClient ? unreadOffersCount : 0,
    unreadAssistantCount: isClient ? unreadAssistantCount : 0,
    unreadTotalCount: isClient ? unreadOffersCount + unreadAssistantCount : 0
  }
}
