import { Center, type CenterProps } from '@chakra-ui/react'
import { AihubStarIcon } from '../../../components/AihubStarIcon'

export type AvatarProps = CenterProps

export const Avatar = ({ children, ...props }: AvatarProps) => {
  return (
    <Center
      className="assistant-avatar"
      borderRadius="50%"
      h="32px"
      overflow="hidden"
      position="relative"
      w="32px"
      color="var(--chakra-colors-text-mild, var(--chakra-colors-gray-500, #ccc))"
      {...props}>
      {/*<IoSparkles />*/}
      <AihubStarIcon fill />

      {children}
    </Center>
  )
}
