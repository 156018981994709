import { useEffect, useRef } from 'react'
import { Center, type StackProps, VStack } from '@chakra-ui/react'
import { AnimatePresence } from 'framer-motion'
import { BouncingDots, MotionChakraBox, NoData } from 'ui'
import { createCn } from 'shared-utils'
import useOnScreen from 'ui/hooks/useOnScreen'
import { useAiOffers } from '../useAiOffers'
import { AihubOffersListItem } from './AihubOffersListItem'

const cn = createCn('aihub-offers-list')

type AiHubOffersListProps = StackProps

export const AiHubOffersList = ({ sx, ...props }: AiHubOffersListProps) => {
  const bottomRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const isInView = useOnScreen(wrapperRef)

  const { offers, isLoading } = useAiOffers()

  useEffect(() => {
    if (isInView) {
      // Scroll to the bottom when module is in view
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [isInView])

  return (
    <VStack
      ref={wrapperRef}
      gap={2}
      className={cn()}
      overflowX="hidden"
      overflowY="auto"
      overscrollBehaviorY="contain"
      p={4}
      py={4}
      w="full"
      flex="1 0 0"
      sx={{
        scrollbarWidth: 'none',
        ...sx
      }}
      {...props}>
      <VStack flex={1} gap={4} w="full" className={cn('container')}>
        {isLoading && (
          <Center className={cn('loader')} flex={1} position="relative" pt={4} w="full">
            <BouncingDots />
          </Center>
        )}

        {!isLoading && !offers?.length && (
          <Center flex={1}>
            <AnimatePresence>
              <MotionChakraBox
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
                transition={{ duration: 0.5 }}>
                <NoData>No deals yet</NoData>
              </MotionChakraBox>
            </AnimatePresence>
          </Center>
        )}

        <AnimatePresence>
          {offers?.map((offer) => (
            <MotionChakraBox
              key={offer.id}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              transition={{ duration: 0.5 }}
              w="full"
              display="flex">
              <AihubOffersListItem offer={offer} />
            </MotionChakraBox>
          ))}
        </AnimatePresence>
      </VStack>

      <div ref={bottomRef} />
    </VStack>
  )
}
