import { type AiOfferDTO, type ICartItemGroup } from 'ecosystem'
import { Button, VStack } from '@chakra-ui/react'
import { useCallback, useMemo } from 'react'
import { useAcceptAiOfferMutation } from 'shared-redux/state'
import { useAiOffersContext } from '../../aihubOffersContext'
import { OfferBase } from './OfferBase'
import { OfferProduct } from './OfferProduct'
import { OfferTotal } from './OfferTotal'
import { OfferHeader } from './OfferHeader'

interface OfferBuyAllProps {
  offer: AiOfferDTO
}

export const OfferBuyAll = ({ offer }: OfferBuyAllProps) => {
  const { handleAddToCart } = useAiOffersContext()
  const [acceptAiOfferMutation, { isLoading: isAcceptingAiOffer }] = useAcceptAiOfferMutation()

  const { total, totalOriginal } = useMemo(() => {
    return offer.products.reduce(
      (acc, item) => {
        return {
          total: acc.total + (item.quantity ?? 1) * item.product.price,
          totalOriginal: acc.totalOriginal + (item.quantity ?? 1) * item.product.originalPrice
        }
      },
      { total: 0, totalOriginal: 0 }
    )
  }, [offer.products])

  const onSubmit = useCallback(() => {
    if (!offer.accepted) {
      acceptAiOfferMutation({ offerId: offer.id, sessionId: offer.sessionId })
    }

    const cartItem: ICartItemGroup = {
      id: offer.id,
      qty: 1,
      type: 'GROUP',
      meta: {
        groupType: {
          name: offer.name,
          items: offer.products.map((p) => ({
            product: p.product,
            originalQty: p.quantity ?? 1,
            qty: p.quantity ?? 1,
            // todo: could be added for BUNDLE products
            subItems: []
          }))
        }
      }
    }

    handleAddToCart(cartItem)
  }, [
    acceptAiOfferMutation,
    handleAddToCart,
    offer.accepted,
    offer.id,
    offer.name,
    offer.products,
    offer.sessionId
  ])

  return (
    <OfferBase>
      <VStack w="full" alignItems="strech" gap={4}>
        <OfferHeader offer={offer} />

        <VStack w="full" alignItems="strech">
          {offer.products.map((item) => (
            <OfferProduct key={item.product.id} product={item} />
          ))}
        </VStack>

        <OfferTotal total={total} totalOriginal={totalOriginal} />

        <Button onClick={onSubmit} isDisabled={isAcceptingAiOffer}>
          Add to the cart
        </Button>
      </VStack>
    </OfferBase>
  )
}
