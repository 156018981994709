import { type AiOfferDTO } from 'ecosystem'
import { Box } from '@chakra-ui/react'
import { useEffect, useRef } from 'react'
import { useOnScreen } from 'ui/hooks'
import { useReadAiOfferMutation } from 'shared-redux/state'
import { OfferBuyOne } from './OfferBuyOne'
import { OfferBuyAll } from './OfferBuyAll'

interface AihubOffersListItemProps {
  offer: AiOfferDTO
}

export const AihubOffersListItem = ({ offer }: AihubOffersListItemProps) => {
  const itemRef = useRef(null)
  const isInView = useOnScreen(itemRef)
  const [readAiOfferMutation, { isLoading }] = useReadAiOfferMutation()

  useEffect(() => {
    if (isInView && !offer.opened && !isLoading) {
      readAiOfferMutation({ offerId: offer.id, sessionId: offer.sessionId })
    }
  }, [isInView, isLoading, offer.id, offer.opened, readAiOfferMutation])

  const renderContent = () => {
    switch (offer.type) {
      case 'buyAll':
        return <OfferBuyAll offer={offer} />
      case 'DISCOUNT':
        return <OfferBuyOne offer={offer} />
    }
  }

  return (
    <Box ref={itemRef} w="full">
      {renderContent()}
    </Box>
  )
}
