import { Button, useBreakpointValue } from '@chakra-ui/react'
import { AnimatePresence, MotionConfig } from 'framer-motion'
import { CounterBadge, MotionChakraBox } from 'ui'
import { IoClose } from 'react-icons/io5'
import { AihubStarIcon } from './AihubStarIcon'
import { useAihubState } from './useAihubState'
import { useAihubUnreadCount } from './useAihubUnreadCount'

const iconVariants = {
  hidden: { scale: 0, rotate: -180, opacity: 0 },
  visible: { scale: 1, rotate: 0, opacity: 1 },
  exit: { scale: 0, rotate: 180, opacity: 0 }
}

export const AihubTrigger = () => {
  const { isOpen, toggleAihub } = useAihubState()
  const { unreadTotalCount } = useAihubUnreadCount()
  const isVisible = useBreakpointValue({ base: false, md: true }) && !isOpen

  return (
    <MotionChakraBox
      initial={{ translateX: isVisible ? '100%' : '-10%' }}
      animate={{ translateX: isVisible ? '100%' : '-10%' }}
      transition={{ type: 'spring', bounce: 0.4, duration: 0.4 }}
      position="absolute"
      right="0"
      top={0}>
      <Button
        onClick={toggleAihub}
        borderEndRadius="3xl"
        size="lg"
        variant="outline"
        minW="1rem"
        h="4rem"
        px={1}
        ml="-1px"
        outline="none"
        borderWidth="1px"
        borderColor="blackAlpha.600"
        borderLeftWidth={0}
        fontSize="xl"
        _focus={{ outline: 'none' }}
        _focusVisible={{ outline: 'none' }}
        {...(isOpen
          ? {
              backgroundColor: 'primary',
              color: 'white'
            }
          : {
              backgroundColor: 'background.default',
              color: 'primary'
            })}>
        <MotionConfig transition={{ type: 'spring', bounce: 0.35, duration: 0.8 }}>
          {isOpen ? (
            <AnimatePresence initial={false} mode="popLayout">
              <MotionChakraBox
                key="close"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={iconVariants}>
                <IoClose />
              </MotionChakraBox>
            </AnimatePresence>
          ) : (
            <>
              {!!unreadTotalCount && (
                <CounterBadge
                  position="absolute"
                  bottom="-3px"
                  right="-3px"
                  value={unreadTotalCount}
                />
              )}

              <AnimatePresence initial={false} mode="popLayout">
                <MotionChakraBox
                  key="sparkles"
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  variants={iconVariants}>
                  {/*<IoSparkles />*/}
                  <AihubStarIcon />
                </MotionChakraBox>
              </AnimatePresence>
            </>
          )}
        </MotionConfig>
      </Button>
    </MotionChakraBox>
  )
}
