import { useCallback } from 'react'
import { type BaseEvent, type PageViewEvent, type SensitivityConfig } from '../typings'
import { useGTMContext } from './useGTMContext'

export function useGTMEvents() {
  const { dataLayerManager, consentManager, isInitialized, config } = useGTMContext()

  const trackPageView = useCallback(
    (path: string, title: string, sensitivity: SensitivityConfig) => {
      if (isInitialized && dataLayerManager && consentManager) {
        const consentState = consentManager.getCurrentConsentState()

        if (sensitivity?.level !== 'basic') {
          const hasConsent = sensitivity?.requiresConsent.every((type) => consentState[type])

          if (!hasConsent) {
            if (config?.isDebugMode) {
              // eslint-disable-next-line no-console -- Expects log in development
              console.warn('Page view tracking blocked: Required consent not given.', {
                required: sensitivity?.requiresConsent,
                current: consentState
              })
            }
            return
          }
        }

        dataLayerManager.pushEvent<PageViewEvent>('page_view_custom', {
          page_path: path,
          page_title: title
        })
      }
    },
    [isInitialized, dataLayerManager, consentManager, config?.isDebugMode]
  )

  const trackEvent = useCallback(
    <T extends BaseEvent>(
      name: T['event'],
      params: Omit<T, 'event'>,
      sensitivity: SensitivityConfig
    ) => {
      if (isInitialized && dataLayerManager && consentManager) {
        const consentState = consentManager.getCurrentConsentState()

        if (sensitivity?.level === 'critical' || sensitivity?.level === 'standard') {
          const hasConsent = sensitivity.requiresConsent.every((type) => consentState[type])

          if (!hasConsent) {
            if (config?.isDebugMode) {
              // eslint-disable-next-line no-console -- Expects log in development
              console.warn(`Event ${name} blocked: Required consent not given.`, {
                required: sensitivity.requiresConsent,
                current: consentState
              })
            }
            return
          }
        }

        dataLayerManager.pushEvent<T>(name, params)
      }
    },
    [isInitialized, dataLayerManager, consentManager, config?.isDebugMode]
  )

  return {
    trackPageView,
    trackEvent,
    isInitialized,
    dataLayerManager,
    consentManager,
    config
  }
}
