'use client'

import { useBreakpointValue, useDisclosure } from '@chakra-ui/react'
import { useCallback, useEffect } from 'react'
import { selectAssistantTeaserState, setTeaserState } from 'shared-redux/state'
import { useDispatch, useSelector } from 'shared-redux'
import { type AssistantTeaserMode } from '../types'

interface UseTeaserProps {
  openAssistant: () => void
  mobileMode?: AssistantTeaserMode
  desktopMode?: AssistantTeaserMode
  delay?: number
}

export const useTeaser = (props: UseTeaserProps) => {
  const dispatch = useDispatch()
  const { mobileMode = 'none', desktopMode = 'none', delay = 0, openAssistant } = props

  const teaserState = useSelector(selectAssistantTeaserState)
  const { isOpen: isOpenTeaser, onOpen: onOpenTeaser, onClose: onCloseTeaser } = useDisclosure()

  const teaserMode = useBreakpointValue<AssistantTeaserMode>({
    base: mobileMode,
    md: desktopMode
  })

  /**
   * Main goal of the teaser is to open chat, when it's opened set state to DONE
   */
  const onTeaserSuccess = useCallback(() => {
    dispatch(setTeaserState('DONE'))
  }, [dispatch])

  const onCloseTeaserHint = useCallback(() => {
    onCloseTeaser()
    dispatch(setTeaserState('CLOSED_DIRECTLY'))
  }, [dispatch, onCloseTeaser])

  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }

    let timer: any

    const returnCb = () => {
      clearTimeout(timer)
    }

    if (teaserState !== 'INITIAL') {
      onCloseTeaser()

      return returnCb
    }

    if (teaserMode === 'chat') {
      timer = setTimeout(() => {
        onTeaserSuccess()
        openAssistant()
      }, delay)
    }

    if (teaserMode === 'hint') {
      timer = setTimeout(() => {
        onOpenTeaser()
      }, delay)
    }

    return returnCb
  }, [
    delay,
    onCloseTeaser,
    onCloseTeaserHint,
    onOpenTeaser,
    onTeaserSuccess,
    openAssistant,
    teaserMode,
    teaserState
  ])

  return {
    teaserMode,
    onTeaserSuccess,
    onCloseTeaserHint,
    isOpenTeaser
  }
}
