/* eslint-disable no-console -- for debug purpose */
import { type FirebaseApp, getApps, initializeApp } from 'firebase/app'
import { getMessaging, getToken, type MessagePayload, onMessage } from 'firebase/messaging'
import { firebaseConfig } from './firebase-config'
import { type FirebaseMessagePayload } from './types'

interface FirebaseNotificationClientOptions {
  isDebug?: boolean
  /** @example '/sw.js' */
  serviceWorkerPath?: string
  /** @remarks Has a priority on serviceWorkerPath param */
  serviceWorkerRegistration?: ServiceWorkerRegistration
}

export class FirebaseNotificationClient {
  private readonly app: FirebaseApp
  private readonly isDebug: boolean
  private readonly serviceWorkerPath: string
  private serviceWorkerRegistration?: ServiceWorkerRegistration
  private readonly waitForServiceWorkerRegistered: Promise<boolean>
  private resolveIsServiceWorkerRegistered: () => void = () => void 0
  private readonly messageListeners: ((payload: FirebaseMessagePayload) => void)[] = []
  private unsubscribeFn: () => void = () => void 0
  private currentRequestTokenPromise: Promise<string | null> | null = null

  constructor(options?: FirebaseNotificationClientOptions) {
    this.isDebug = !!options?.isDebug
    this.serviceWorkerPath = options?.serviceWorkerPath ?? '/firebase-messaging-sw.js'
    this.serviceWorkerRegistration = options?.serviceWorkerRegistration

    this.waitForServiceWorkerRegistered = new Promise((resolve) => {
      this.resolveIsServiceWorkerRegistered = () => {
        resolve(true)
      }
    })

    if (getApps().length === 0) {
      this.app = initializeApp(firebaseConfig)
    } else {
      this.app = getApps()[0]
    }
  }

  /**
   * Waits for registration to become active. MDN documentation claims that
   * a service worker registration should be ready to use after awaiting
   * navigator.serviceWorker.register() but that doesn't seem to be the case in
   * practice, causing the SDK to throw errors when calling
   * swRegistration.pushManager.subscribe() too soon after register(). The only
   * solution seems to be waiting for the service worker registration `state`
   * to become "active".
   * @see https://github.com/firebase/firebase-js-sdk/blob/main/packages/messaging/src/helpers/registerDefaultSw.ts
   */
  private async waitForRegistrationActive(registration: ServiceWorkerRegistration): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const incomingSw = registration.installing || registration.waiting

      if (registration.active) {
        resolve()
      } else if (incomingSw) {
        const handler = (ev: Event) => {
          if ((ev.target as ServiceWorker)?.state === 'activated') {
            incomingSw.onstatechange = null
            incomingSw.removeEventListener('statechange', handler)
            resolve()
          }
        }

        incomingSw.addEventListener('statechange', handler)
      } else {
        reject(new Error('No incoming service worker found.'))
      }
    })
  }

  private async registerServiceWorker() {
    if (this.serviceWorkerRegistration) {
      return this.serviceWorkerRegistration
    }

    if ('serviceWorker' in navigator) {
      try {
        this.serviceWorkerRegistration = await navigator.serviceWorker.register(
          this.serviceWorkerPath
        )

        this.resolveIsServiceWorkerRegistered()

        return this.serviceWorkerRegistration
      } catch (err) {
        console.log('Service worker registration failed, error:', err)
      }
    } else {
      console.log("Browser doesn't support navigator.serviceWorker")
    }

    return undefined
  }

  private async _requestToken(): Promise<string | null> {
    try {
      if (typeof window !== 'undefined' && 'Notification' in window) {
        const permission =
          Notification.permission === 'granted'
            ? Notification.permission
            : await Notification.requestPermission()

        if (permission === 'granted') {
          const messaging = getMessaging(this.app)

          const serviceWorkerRegistration = await this.registerServiceWorker()

          if (serviceWorkerRegistration) {
            await this.waitForRegistrationActive(serviceWorkerRegistration)
          }

          return await getToken(messaging, {
            vapidKey: process.env.NEXT_PUBLIC_FIREBASE_VAPID_KEY,
            serviceWorkerRegistration
          })
        }
      }

      return null
    } catch (error) {
      if (this.isDebug)
        console.error(
          'Unable to get permission to notify.',
          'This happens if the user denied the notification permission.',
          error
        )
      return null
    }
  }

  /** Register ServiceWorker, request permissions and return FCM token */
  public requestToken(): Promise<string | null> {
    if (this.currentRequestTokenPromise) {
      return this.currentRequestTokenPromise
    }

    this.currentRequestTokenPromise = this._requestToken()
    return this.currentRequestTokenPromise
  }

  private subscribeOnBackgroundMessages(callback: (payload: MessagePayload) => void) {
    let isUnsubscribed = false
    let unsubscribe = () => {
      isUnsubscribed = true
    }

    ;(async () => {
      await this.waitForServiceWorkerRegistered
      if (this.serviceWorkerRegistration) {
        await this.waitForRegistrationActive(this.serviceWorkerRegistration)
      }

      if (!this.serviceWorkerRegistration?.active || isUnsubscribed) {
        return
      }

      const eventListenerHandler = (event: Event) => {
        type CustomEventData = MessagePayload & {
          isFirebaseMessaging?: boolean
          messageType?: 'push-received'
        }
        const eventData = (event as Event & { data?: CustomEventData })?.data

        // if message comes only for forehand from FCM, skip it. This message would be processed by onMessage()
        if (eventData?.isFirebaseMessaging && eventData?.messageType === 'push-received') {
          return
        }

        // If message comes from onBackgroundMessages from serviceWorker when application is opened but not on focus
        callback(eventData as unknown as MessagePayload)
      }

      window.navigator.serviceWorker.addEventListener('message', eventListenerHandler)

      unsubscribe = () => {
        window.navigator.serviceWorker.removeEventListener('message', eventListenerHandler)
        isUnsubscribed = true
      }
    })()

    return () => {
      unsubscribe()
    }
  }

  private notifyListeners(payload: FirebaseMessagePayload) {
    this.messageListeners.forEach((listener) => listener(payload))
  }

  private setupMessageListeners() {
    const messaging = getMessaging(this.app)

    const unsubscribeOnMessage = onMessage(messaging, (payload: MessagePayload) => {
      if (this.isDebug) {
        console.log('Firebase foreground message received:', payload)
      }

      this.notifyListeners(payload as FirebaseMessagePayload)
    })

    const unsubscribeOnBackgroundMessage = this.subscribeOnBackgroundMessages(
      (payload: MessagePayload) => {
        if (this.isDebug) {
          console.log('Firebase foreground from background message received:', payload)
        }

        this.notifyListeners(payload as FirebaseMessagePayload)
      }
    )

    return () => {
      unsubscribeOnMessage()
      unsubscribeOnBackgroundMessage()
    }
  }

  public onMessageListener(callback: (payload: FirebaseMessagePayload) => void) {
    this.messageListeners.push(callback)

    if (this.messageListeners.length === 1) {
      this.unsubscribeFn = this.setupMessageListeners()
    }

    return () => {
      this.messageListeners.splice(this.messageListeners.indexOf(callback), 1)

      if (this.messageListeners.length === 0) {
        this.unsubscribeFn()
      }
    }
  }
}
