import { type DataCollectionConfig } from '../typings'
import { ConsentManager } from './consent-manager'
import { DataLayerManager } from './data-layer-manager'

export class GtmSetup {
  private static instances = new Map<string, GtmSetup>()
  private readonly consentManager: ConsentManager
  private readonly dataLayerManager: DataLayerManager
  private initialized = false

  private constructor(private config: DataCollectionConfig) {
    this.consentManager = new ConsentManager(this.config)
    this.dataLayerManager = new DataLayerManager(this.config, this.consentManager)
  }

  public static getInstance(config: DataCollectionConfig): GtmSetup {
    const instanceKey = config.gtmConfig.centralContainerId

    if (!GtmSetup.instances.has(instanceKey)) {
      GtmSetup.instances.set(instanceKey, new GtmSetup(config))
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- We know it's there
    return GtmSetup.instances.get(instanceKey)!
  }

  public initialize(): void {
    if (this.initialized) {
      throw new Error('GTM Setup already initialized')
    }

    try {
      this.initialized = true
    } catch (error) {
      this.destroy()
      throw new Error(
        `Failed to initialize GTM Setup: ${error instanceof Error ? error.message : 'Unknown error'}`
      )
    }
  }

  public updateConfig(newConfig: DataCollectionConfig): void {
    if (this.initialized) {
      throw new Error('Cannot update config after initialization')
    }
    this.config = newConfig
  }

  public destroy(): void {
    try {
      this.consentManager?.destroy()
      this.dataLayerManager?.destroy()
      this.initialized = false
      GtmSetup.instances.delete(this.config.gtmConfig.centralContainerId)
    } catch (error) {
      // eslint-disable-next-line no-console -- Log expected
      console.error('Error during GTM Setup cleanup:', error)
    }
  }

  public isInitialized(): boolean {
    return this.initialized
  }

  public getConsentManager(): ConsentManager {
    if (!this.initialized) {
      throw new Error('GTM Setup not initialized')
    }
    return this.consentManager
  }

  public getDataLayerManager(): DataLayerManager {
    if (!this.initialized) {
      throw new Error('GTM Setup not initialized')
    }
    return this.dataLayerManager
  }
}
