import { useEffect, useRef, useState } from 'react'
import { GtmSetup } from '../managers/gtm-setup'
import { type DataCollectionConfig } from '../typings'
import { type ConsentManager } from '../managers/consent-manager'
import { type DataLayerManager } from '../managers/data-layer-manager'

export function useGTMSetup(config: DataCollectionConfig) {
  const gtmSetupRef = useRef<GtmSetup | null>(null)
  const [error, setError] = useState<Error | null>(null)
  const [managers, setManagers] = useState<{
    consentManager: ConsentManager | null
    dataLayerManager: DataLayerManager | null
  }>({
    consentManager: null,
    dataLayerManager: null
  })

  useEffect(() => {
    let mounted = true

    const initializeGTM = () => {
      try {
        // Only initialize if not already initialized
        if (!gtmSetupRef.current?.isInitialized()) {
          if (!gtmSetupRef.current) {
            gtmSetupRef.current = GtmSetup.getInstance(config)
          }

          gtmSetupRef.current.initialize()

          if (mounted) {
            setError(null)
            setManagers({
              consentManager: gtmSetupRef.current.getConsentManager(),
              dataLayerManager: gtmSetupRef.current.getDataLayerManager()
            })
          }
        }
      } catch (err) {
        if (mounted) {
          setError(err instanceof Error ? err : new Error('Failed to initialize GTM'))
          setManagers({
            consentManager: null,
            dataLayerManager: null
          })
        }
      }
    }

    initializeGTM()

    return () => {
      mounted = false
      // Don't destroy on route changes, only on unmount
      if (typeof window === 'undefined') {
        if (gtmSetupRef.current) {
          gtmSetupRef.current.destroy()
          gtmSetupRef.current = null
          setManagers({
            consentManager: null,
            dataLayerManager: null
          })
        }
      }
    }
  }, [config])

  return {
    consentManager: managers.consentManager,
    dataLayerManager: managers.dataLayerManager,
    error,
    isInitialized: gtmSetupRef.current?.isInitialized() ?? false,
    config
  }
}
