import { type FC, type ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { createContext } from 'ui/lib/react-utils'
import { FirebaseNotificationClient } from './firebase'
import { type NotificationMessage, type NotificationsContextValue } from './types'
import { type FirebaseMessagePayload } from './firebase/types'

const [NotificationsContextProvider, useNotificationsContext] =
  createContext<NotificationsContextValue>({
    name: 'Notifications'
  })

export { useNotificationsContext }

interface NotificationsProviderProps {
  children: ReactNode
  isDebug?: boolean
  /** @example '/sw.js' */
  serviceWorkerPath?: string
  /** @remarks Has a priority on serviceWorkerPath param */
  serviceWorkerRegistration?: ServiceWorkerRegistration

  autoRequestToken?: boolean
  autoRequestTokenDelay?: number
}

export const NotificationsProvider: FC<NotificationsProviderProps> = ({
  children,
  isDebug,
  serviceWorkerPath,
  serviceWorkerRegistration,
  autoRequestToken,
  autoRequestTokenDelay = 0
}) => {
  const [permission, setPermission] = useState<NotificationPermission>(() =>
    typeof window !== 'undefined' && 'Notification' in window ? Notification.permission : 'default'
  )
  const [token, setToken] = useState<string | null>(null)
  const [notification, setNotification] = useState<NotificationMessage | null>(null)
  const [client, setClient] = useState<FirebaseNotificationClient>(
    new FirebaseNotificationClient({ isDebug, serviceWorkerPath, serviceWorkerRegistration })
  )
  const isInitialRequest = useRef(false)

  useEffect(() => {
    setClient(
      new FirebaseNotificationClient({ isDebug, serviceWorkerPath, serviceWorkerRegistration })
    )
  }, [isDebug, serviceWorkerPath, serviceWorkerRegistration])

  const onMessage = useCallback(
    (cb: (message: NotificationMessage) => void) => {
      if (!client) return () => void 0

      return client.onMessageListener((fcmMessage: FirebaseMessagePayload) => {
        const message: NotificationMessage = {
          id: fcmMessage.data?.id ?? fcmMessage.messageId,
          type: fcmMessage.data?.category === 'DEAL' ? 'offer' : 'other',
          title: fcmMessage.data?.message ?? fcmMessage.notification?.title ?? 'New message',
          body: fcmMessage.notification?.body ?? '',
          data: fcmMessage.data
        }

        cb(message)
      })
    },
    [client]
  )

  const requestToken = useCallback(async () => {
    const fcmToken = await client.requestToken()

    setPermission(Notification.permission)

    if (fcmToken) {
      setToken(fcmToken)
    }

    return fcmToken
  }, [client])

  useEffect(() => {
    if (permission === 'granted' && token === null && !isInitialRequest.current) {
      isInitialRequest.current = true
      void requestToken().finally(() => {
        isInitialRequest.current = false
      })
    }

    return () => {
      isInitialRequest.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- run only once after mounting component
  }, [requestToken])

  useEffect(() => {
    if (!autoRequestToken || token || isInitialRequest.current) {
      return
    }

    const timer = setTimeout(() => {
      isInitialRequest.current = true
      void requestToken().finally(() => {
        isInitialRequest.current = false
      })
    }, autoRequestTokenDelay)

    return () => {
      clearTimeout(timer)
    }
  }, [autoRequestToken, autoRequestTokenDelay, permission, requestToken, token])

  useEffect(() => {
    const unsubscribe = onMessage((payload) => {
      setNotification(payload)
    })

    return () => {
      unsubscribe()
    }
  }, [onMessage])

  const value: NotificationsContextValue = {
    token,
    notification,
    onMessage,
    requestToken,
    permission
  }

  return <NotificationsContextProvider value={value}>{children}</NotificationsContextProvider>
}
