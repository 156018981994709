import { GrandLink, ImageBox, Price } from 'ui'
import { Box, HStack, type StackProps, VStack } from '@chakra-ui/react'
import { type AiOfferProductDTO } from 'ecosystem'
import { getProductImages } from 'api'
import { useAiOffersContext } from '../../aihubOffersContext'

interface OfferProductProps extends StackProps {
  product: AiOfferProductDTO
}

export const OfferProduct = ({ product: aiOfferProduct, ...props }: OfferProductProps) => {
  const { paths } = useAiOffersContext()
  const { product, quantity } = aiOfferProduct
  const { mainImageUrl } = getProductImages(product.medias)

  return (
    <HStack
      w="auto"
      alignItems="stretch"
      className="offer-product"
      fontWeight="normal"
      overflow="hidden"
      p={0}
      boxShadow="0 0px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)"
      borderRadius="md"
      {...props}>
      <ImageBox
        as={GrandLink}
        // @ts-expect-error -- as link
        href={`${paths.products}/${product.slug}`}
        h="auto"
        minH="100px"
        backgroundColor="background.surface"
        imageProps={{
          title: product.name,
          alt: product.name,
          src: mainImageUrl,
          fill: true,
          style: {
            objectFit: 'contain'
          },
          sizes: '200px'
        }}
        w="100%"
        maxW="30%"
        minW="100px"
        p={{
          base: 2,
          md: 2
        }}
      />

      <VStack alignItems="flex-start" fontSize="xs" p={2} justifyContent="space-between" flex={1}>
        <VStack alignItems="flex-start" gap={1}>
          <Box color="text.mild" fontSize="2xs" textTransform="uppercase">
            {product.brand?.name}
          </Box>

          <Box fontWeight="semibold" fontSize="sm">
            {product.name}
          </Box>
        </VStack>

        <HStack gap={1}>
          <Box fontSize="sm">{`${quantity ?? 1} x `}</Box>
          <Price
            align="center"
            orientation="horizontal"
            price={product.price}
            originalPrice={product.originalPrice}
          />
        </HStack>
      </VStack>
    </HStack>
  )
}
