import { type FC } from 'react'
import { type ChatMessageProps } from './types'
import { ChatMessageExternal } from './ChatMessageExternal'
import { ChatMessageClient } from './ChatMessageClient'

export const ChatMessage: FC<ChatMessageProps> = ({
  onSpeak,
  renderProductsComponent,
  msg,
  error,
  isLoadingSpeech,
  isPlayingSpeech,
  ...props
}) => {
  const isBot = msg.role === 'assistant'

  if (isBot) {
    return (
      <ChatMessageExternal
        onSpeak={onSpeak}
        renderProductsComponent={renderProductsComponent}
        msg={msg}
        isLoadingSpeech={isLoadingSpeech}
        isPlayingSpeech={isPlayingSpeech}
        {...props}
      />
    )
  }

  return <ChatMessageClient error={error} msg={msg} />
}
